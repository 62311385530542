import React from "react";
import {
  Typography,
  Box,
  Paper,
  Grid,
  Skeleton,
  useTheme,
  useMediaQuery,
} from "@mui/material";

const CardLoader = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); 

  return (
    <Paper
      variant="outlined"
      sx={{
        height: isSmallScreen ? "auto" : "350px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        padding: "16px",
        gap: "16px",
        overflowY: isSmallScreen ? "auto" : "visible", 
        maxHeight: "100%",
      }}
    >
      <Typography variant="h6" sx={{ fontSize: "14px" }}>
        Currency Calculator
      </Typography>

      {/* Loader for "You send" section */}
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="body1"
          sx={{ fontSize: "12px", fontFamily: "PPMori-Regular" }}
        >
          You send
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Box>

      {/* Loader for "Recipient gets" section */}
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          variant="body1"
          sx={{ fontSize: "12px", fontFamily: "PPMori-Regular" }}
        >
          Recipient gets
        </Typography>
        <Box display="flex" gap={1} alignItems="center">
          <Skeleton variant="text" width={100} height={20} />
          <Skeleton variant="text" width={150} height={20} />
        </Box>
      </Box>

      {/* Loader for exchange rate, fees, and total amounts */}
      <Box display="flex" flexDirection="column" gap={1}>
        {[
          "Exchange rate",
          "Total fee",
        ].map((label, index) => (
          <Grid container key={index} alignItems="center">
            <Grid item xs={6}>
              <Typography
                variant="body1"
                sx={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
              >
                {label}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ textAlign: "right" }}>
              <Skeleton variant="text" width={80} height={20} />
            </Grid>
          </Grid>
        ))}
      </Box>
    </Paper>
  );
};

export default CardLoader;
