import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { Button, Typography, Box } from "@mui/material";
import { toast } from "react-hot-toast";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import WarningIcon from "@mui/icons-material/Warning";

const Notification = ({ open, onClose, message }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={2000}
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "right" }} // Positioning the notification
    >
      <Alert onClose={onClose} severity="success" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;

const notifySuccess = ({ opt, ...props }) => {
  toast.custom(<Success {...props} />, {
    ...opt,
  });
};

const notifyError = ({ opt, ...props }) => {
  toast.custom(<Error {...props} />, { ...opt });
};

const notifyWarning = ({ opt, ...props }) => {
  toast.custom(<Warning {...props} />, { ...opt });
};

const sizeVariant = {
  large: { padding: "20px", width: "448px" },
  medium: { padding: "15px", width: "446px" },
  small: { padding: "10px", width: "438px" },
};

const Success = (props) => (
  <Box
    sx={{
      border: "1px solid #eae9e7",
      borderLeft: "4px solid #07b134",
      backgroundColor: "#ffffff",
      borderRadius: 1,
      display: "flex",
      alignItems: "flex-start",
      gap: 1,
      ...sizeVariant[props.size || "medium"],
      ...props.style,
    }}
  >
    <CheckCircleOutlinedIcon
      sx={{
        color: "#07b134",
      }}
    />
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="body1" fontSize={14} fontWeight={600}>
        {props.message}
      </Typography>
      {props.description && (
        <Typography variant="body2" fontSize={14}>
          {props.description}
        </Typography>
      )}
    </Box>
  </Box>
);

const Error = (props) => (
  <Box
    sx={{
      border: "1px solid #eae9e7",
      borderLeft: "4px solid #dd271c",
      backgroundColor: "#ffffff",
      borderRadius: 1,
      display: "flex",
      alignItems: "flex-start",
      gap: 1,
      ...sizeVariant[props.size || "medium"],
      ...props.style,
    }}
  >
    <CancelOutlinedIcon
      sx={{
        color: "#dd271c",
      }}
    />
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="body1" fontSize={14} fontWeight={600}>
        {props.message}
      </Typography>
      {props.description && (
        <Typography variant="body2" fontSize={14} color={"#e65244"}>
          {props.description}
        </Typography>
      )}
    </Box>
  </Box>
);

const Warning = (props) => (
  <Box
    sx={{
      border: "1px solid #f1c40f",
      borderLeft: "4px solid #f1c40f",
      backgroundColor: "#ffffff",
      borderRadius: 1,
      display: "flex",
      alignItems: "flex-start",
      gap: 1,
      ...sizeVariant[props.size || "medium"],
      ...props.style,
    }}
  >
    <WarningIcon
      sx={{
        color: "#f1c40f",
      }}
    />
    <Box display={"flex"} flexDirection={"column"} gap={1}>
      <Typography variant="body1" fontSize={14} fontWeight={600}>
        {props.message}
      </Typography>
      {props.description && (
        <Typography variant="body2" fontSize={14}>
          {props.description}
        </Typography>
      )}
    </Box>
  </Box>
);

export const notify = {
  success: notifySuccess,
  error: notifyError,
  warning: notifyWarning,
};
