import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf';
import { formatDate } from '../../lib/service/AuthToken';
import Notification from '../../components/Notification/Notification'; 
import 'jspdf-autotable';
import './BasicPopover.css';
import Papa from 'papaparse';
import commonHelpers from '../../utils/common.helpers';

export default function BasicPopover({setAnchorEl,anchorEl,handleOpen, rowData}) {
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const navigate = useNavigate();
  
   const handleClose = () => {
    setAnchorEl(null);
  };

  const repeatTranscation = () => {
    navigate('/transferAmount');
  }

  const exportPdf = () => {
    const doc = new jsPDF('landscape');

    const tableBody = [];
      const {
        txnId,
        recipientData: { firstName, lastName, bankName, bankAccountNumber },
        sourceAmount,
        destinationAmount,
        fxRate,
        transferPurpose,
        createdOn,
        chargesTotal,
        destinationCurrency,
        sourceCurrency,
      } = rowData;
  
      tableBody.push([
        new Date(createdOn).toLocaleString(),
        txnId,
        `${firstName} ${lastName}`,
        bankName,
        bankAccountNumber,
        `${sourceAmount} ${sourceCurrency}`,
        `${destinationAmount} ${destinationCurrency}`,
        fxRate,
        chargesTotal,
        transferPurpose,
      ]);
  

    const tableHeaders = [
      "Date", "Transaction ID","Recipient Name", "Bank Name", "Bank Account No.",
      "Source Amount", "Destination Amount", "FX Rate", "Total Charges", "Transfer Purpose"];
  
    doc.autoTable({
      head: [tableHeaders],
      body: tableBody,
      startY: 20,
      theme: 'grid',
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },
      headStyles: {
        fillColor: [100, 100, 100],
        textColor: [255, 255, 255],
        halign: 'center',
        valign: 'middle',
        fontSize: 9,
      },
      margin: { top: 30 }
    });
  
    const fileName = "transaction_history.pdf";
    doc.save(fileName);
    setNotificationOpen(true);
    setNotificationMessage(`File "${fileName}" created successfully`);
  };

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const exportCsv = () => {

      const charges = rowData.chargesData.reduce((acc, charge) => {
          acc[charge.chargeType] = charge.chargeAmount + " " + charge.chargeCurrency;
          return acc;
        }, {});
  
      const csvData =  [{
        'Date': formatDate(rowData.updatedOn) || '',
        'Transaction ID': rowData.txnId,
        'Recipient Name': commonHelpers.capitalizeFirstLetter(`${rowData.recipientData.firstName} ${rowData.recipientData.lastName}`),
        'Bank Name': rowData.recipientData.bankName || '',
        'Bank Account No.': rowData.recipientData.bankAccountNumber || '',
        'IFSC': rowData.recipientData.ifsc || '',
        'SWIFT/BIC': rowData.recipientData.swiftBic || '',
        'Source Amount': rowData.sourceAmount + " " + rowData.sourceCurrency,
        'Destination Amount': rowData.destinationAmount + " " + rowData.destinationCurrency,
        'FX Rate': rowData.fxRate || '',
        'Transfer Purpose': rowData.transferPurpose || '',
        'Source of Funds': rowData.fundsSource || '',
        'Flat Fee': charges.FLAT_FEE || '0 USD',
        'Variable Fee': charges.VARIABLE_FEE || '0 USD',
        'Exchange Markup Fee': charges.EXCHANGE_MARKUP_FEE || '0 USD',
        'Tax on Source': charges.TAX_ON_SOURCE || '0 USD',
        'Tax on Destination': charges.TAX_ON_DESTINATION || '0 USD',
        'Off Ramp Fee': charges.OFF_RAMP_FEE || '0 USD',
        'Gas Fee': charges.GAS_FEE || '0 USD',
        'Total Charges': rowData.chargesTotal || '',
        'Status': rowData.status || '',
        'Attached document(s)': rowData.invoiceFileUrl || 'No document available',
      }];
  
    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'transaction_history.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Exporting CSV is not supported in this browser.");
    }
  
    setNotificationOpen(true);
    setNotificationMessage(`File "transaction_history.csv" created successfully`);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='popoverHead'
      >
        {/* <Typography className="popup" onClick={repeatTranscation}>
          Repeat transaction
        </Typography> */}
        <Typography className="popup" onClick={exportPdf}>
          Export PDF
        </Typography>
        <Typography className="popup" onClick={exportCsv}>
          Export CSV
        </Typography>
      </Popover>
      <Notification open={notificationOpen} onClose={handleNotificationClose} message={notificationMessage}/>
    </div>
  );
}
