import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TableCell,
  TableRow,
  IconButton,
  Box,
  Collapse,
  Table,
  TableHead,
  TableBody,
  Paper,
  TableContainer,
  Typography,
  Select,
  MenuItem,
  TablePagination,
  Stack
} from '@mui/material';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon,
} from '@mui/icons-material';
import BasicPopover from "../../components/Popover/BasicPopover";
import EmptyTableComponent from "./EmptyTableComponent";
import './TableComponent.css';
import dot from "../../Images/dot.png";
import RenderSkeletonRow from "../LoaderUI/TableLoader"
import { formatDate } from '../../lib/service/AuthToken';
import commonHelpers from '../../utils/common.helpers';
const tabledata = {
  title: "Send money",
  recipient: "No transactions yet",
  description:
    "Make a transaction to view the activity here.",
};
const transcationData = [
  "Recipient Name",
  "Type",
  "Currency",
  "Amount",
  "Deposit Method",
  "Date Created",
  "Status"
];

const FilledRowComponent = ({ row }) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleOpen = () => {
    setOpen(!open)
  };

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };
  
  const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));
  const imagePath = images[`${(row.sourceCurrency).toLowerCase()}.png`];

  const feeData = () => {
    let feeData = []
    const flatFee = row?.chargesData?.find(fd => fd.chargeType === "FLAT_FEE");
    if(Object.keys(flatFee).length){
      feeData.push({
        label: `${flatFee.chargeName}`,
        value: `${flatFee.chargeAmount} ${flatFee.chargeCurrency}`
      })
    }
    const variableFee = row?.chargesData?.find(fd => fd.chargeType === "VARIABLE_FEE");
    if(Object.keys(variableFee).length){
      feeData.push({
        label: `${variableFee.chargeName}`,
        value: `${variableFee.chargeAmount} ${variableFee.chargeCurrency}`
      })
    }
    feeData.push({
      label: 'Total fee',
      value: `${row?.chargesTotal} ${row?.chargesTotalCurrency}` || "-"
    })
    return feeData;
  }

  const handleDownload = () => {
    const link = document.createElement('a');
    link.href = row?.invoiceFileUrl;
    link.setAttribute('download', 'Transaction.docx');
    link.setAttribute('target', '_blank');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <TableRow  sx={{ "& > *": { borderBottom: "unset" } }}
        style={{ fontFamily: "PPMori-Regular" }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={handleOpen}
          >
            {open ? <KeyboardArrowDownIcon sx={{ color: '#462A9C' }} /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell><span style={{ fontSize: '14px' }}> {commonHelpers.capitalizeFirstLetter(`${row.recipientData.firstName} ${row.recipientData.lastName}`)}</span></TableCell>
        {/* <TableCell component="th" scope="row">
          <span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{row.txnId}</span>
        </TableCell> */}
        <TableCell className='tablecell'><span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{row.recipientData.recipientType}</span></TableCell>
        <TableCell className='tablecell'><span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular', fontWeight: 'bold' }}>{row.sourceAmount}</span></TableCell>
        <TableCell>
          <span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>
          <img src={imagePath} style={{ width: "15px", marginRight: '5px' }} />
          {row.sourceCurrency}
          </span></TableCell>
        <TableCell className='tablecell'><span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{row.depositType === "CRYPTO_MANUAL_WALLET" ? "QR Manual Payment" : "Connected Wallet"}</span></TableCell>
        <TableCell className='tablecell'><span style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}>{formatDate(row.updatedOn)}</span></TableCell>
        <TableCell className='tablecell'>
          <span className={
            row.status == 'INITIATED' || row.status == 'PENDING'
              ? 'status-pending'
              : row.status == 'REJECTED' || row.status == 'FAILED'
                ? 'status-failed'
                : row.status == 'COMPLETE'
                  ? 'status-active'
                  : 'status-active'
          }>{row.status}</span>
        </TableCell>
        <TableCell align="left" className='tablecell'>
          <img src={dot} alt="" className="dot" onClick={handleClick} />
        </TableCell>
        <BasicPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl} handleOpen={handleOpen} rowData={row} />
      </TableRow>
      <TableRow style={{ backgroundColor: "#FBFAFC" }}>
        <TableCell style={{ padding: open ? '16px 40px' : 0}} colSpan={15}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ display: 'flex', width: '100%', gap: 2 }}>
              <Box
               sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                gap: 2,
                borderRight: 1,
                borderColor: "rgba(0,0,0,0.2)"
              }}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Amount and fees
                </Typography>
                <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
                  <Stack sx={{ gap: 2 }}>
                    <Box>
                      <Typography fontSize={12} color={"gray"}>
                        Total amount sent
                      </Typography>
                      <Typography fontSize={12}>{row.sourceAmount}{ " " }{row.sourceCurrency}</Typography>
                    </Box>
                    <Box>
                      <Typography fontSize={12} color={"gray"}>
                        Recipent gets
                      </Typography>
                      <Typography fontSize={12}>
                      {row.destinationAmount}{" "}{row.destinationCurrency}
                      </Typography>
                    </Box>
                  </Stack>
                  <Stack mr={3} sx={{ gap: 2 }}>
                    {
                      feeData() && feeData().map(item => (
                        <Box>
                          <Typography fontSize={12} color={"gray"}>
                            {item.label}
                          </Typography>
                          <Typography fontSize={12}>{item.value}</Typography>
                        </Box>
                      ))
                    }
                  </Stack>
                </Stack>
              </Box>
              <Box
                 sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  borderRight: 1,
                  borderColor: "rgba(0,0,0,0.2)",
                  px: 3
                }}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Recipient
                </Typography>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Full name
                  </Typography>
                  <Typography fontSize={12}>
                    {commonHelpers.capitalizeFirstLetter(`${row.recipientData.firstName} ${row.recipientData.lastName}`)}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Bank account
                  </Typography>
                  <Typography fontSize={12}>
                    {row.recipientData.bankAccountNumber}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Destination currency
                  </Typography>
                  <Typography fontSize={12}>
                    {row.destinationCurrency}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  px: 3
                }}
              >
                <Typography fontSize={14} fontWeight={600}>
                  Payment details
                </Typography>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Transcation Id
                  </Typography>
                  <Typography fontSize={12}>
                   {row.txnId}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                    Payment type
                  </Typography>
                  <Typography fontSize={12}>
                   {row.endlTransactionMode}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                   Deposit method
                  </Typography>
                  <Typography fontSize={12}>
                    {row.depositType === "CRYPTO_MANUAL_WALLET" ? "QR Manual Payment" : "Connected Wallet"}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize={12} color={"gray"}>
                   Attached document(s)
                  </Typography>
                  <Typography fontSize={12} sx={{ color: row.invoiceFileUrl ? 'blue' : '#202021', textDecoration: row.invoiceFileUrl ? 'underline' : 'none', cursor: row.invoiceFileUrl ? 'pointer' : 'default' }} onClick={row.invoiceFileUrl ? handleDownload : null}>
                  {row.invoiceFileUrl ? 'View Document(s)' : 'No documents attached' }
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

FilledRowComponent.propTypes = {
  row: PropTypes.object.isRequired,
};

const FilledTableComponent = ({ rows, loading, page, rowsPerPage, totalCount, onChangePage, onChangeRowsPerPage }) => {
  return (
    <TableContainer component={Paper} style={{ width: "100%", height: "100%" }}>
      <Table  aria-label="collapsible table"
        style={{ fontFamily: "PPMori-Regular" }}>
        <TableHead  sx={{
            position: "sticky",
            top: 0,
            zIndex: 9,
          }}>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#F6F5F9' }} />
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Recipient Name</TableCell>
            {/* <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Transaction ID</TableCell> */}
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Type</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Amount</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Currency</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Deposit Method</TableCell>
            <TableCell sx={{ borderRight: '1px solid #ccc', backgroundColor: '#F6F5F9', fontWeight: 'bold', fontFamily: 'PPMori-Regular', fontSize: '14px' }}>Date Created</TableCell>
            <TableCell sx={{ backgroundColor: '#F6F5F9', fontWeight: 'bold' }}>Status</TableCell>
            <TableCell sx={{ backgroundColor: '#F6F5F9', fontWeight: 'bold' }} />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <FilledRowComponent key={row.txnId} row={row} />
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 15]}
        component="div"
        count={totalCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
      />
    </TableContainer>
  );
};

FilledTableComponent.propTypes = {
  rows: PropTypes.array.isRequired,
};

const TransactionTable = ({ rows, loading, page, rowsPerPage, totalCount, onChangePage, onChangeRowsPerPage }) => {
  return (
    <>
      {loading ? (
        <RenderSkeletonRow rowHeadingdata={transcationData} />
      ) : totalCount === 0 ? (
        <EmptyTableComponent tabledata={tabledata} rowHeadingdata={transcationData} />
      ) :
        (
          <FilledTableComponent rows={rows} loading={loading} page={page} rowsPerPage={rowsPerPage} totalCount={totalCount} onChangePage={onChangePage} onChangeRowsPerPage={onChangeRowsPerPage} />
        )
      }
    </>
  );
};

TransactionTable.propTypes = {
  rows: PropTypes.array.isRequired,
};

export default TransactionTable;
