import React, { useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Grid,
  Box,
  Typography,
  Stack,
  IconButton,
} from "@mui/material";
import { ReactComponent as TableIcon } from "../../Images/TableIcon.svg";
import { ReactComponent as Clipboardlist } from "../../Images/clipboardlist.svg";
import { ReactComponent as Coins } from "../../Images/coins.svg";
import { ReactComponent as Users } from "../../Images/users.svg";
import LogoutIcon from "@mui/icons-material/Logout";
import { ReactComponent as Help } from "../../Images/help.svg";
import endlMainLogo from "../../Images/brandings/endlMainLogo.png";
import { NavLink, useLocation } from "react-router-dom";
import { useProfile } from "../../context/ProfileContext";
import { clearAuthToken } from "../../lib/service/AuthToken";
import { notify } from "../../components/Notification/Notification";
import { useNavigate } from "react-router-dom";
import LogoutModal from "../../components/LogoutModal/LogoutModal";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from "../../store/slices/userSlice";
import { Menu as MenuIcon } from "@mui/icons-material";
import useMediaQuery from "@mui/material/useMediaQuery";
import commonHelpers from '../../utils/common.helpers';

const Navbar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [expandedItems, setExpandedItems] = useState(null);
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const { profile, fullName, previewUrl, setPreviewUrl, logout } = useProfile();
  const dispatch = useDispatch();
  const isKYCVerified = profile?.kycInfo?.kycStatus === "COMPLETED";

  const userprofile = useSelector(state => state.user);

  const navItems = [
    { text: "Dashboard", icon: <TableIcon />, URL: "/" },
    {
      text: "Accounts",
      icon: <Clipboardlist />,
      URL: "/accounts",
      disabled: !isKYCVerified,
    },
    {
      text: "Transactions",
      icon: <Coins />,
      URL: "/transactions",
      disabled: !isKYCVerified,
    },
    {
      text: "Recipients",
      icon: <Users />,
      URL: "/recipients",
      disabled: !isKYCVerified,
    },
  ];

  const handleExpandItem = (itemText) => {
    setExpandedItems(expandedItems === itemText ? null : itemText);
  };

  const handleLogoutClick = () => {
    setOpenLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenLogoutDialog(false);
  };

  const handleLogout = () => {
    clearAuthToken();
    dispatch(userActions.setIsAuthenticated(false));
    dispatch(userActions.resetProfileData());
    setPreviewUrl(null);
    setOpenLogoutDialog(false);
    logout();
    navigate("/login", { replace: true });
    notify.success({
      message: "You are successfully logged out.",
    });
  };

  const showMyprofile = () => {
    navigate("/myprofile");
  };

  const getIconStyle = (path) => {
    return location.pathname === path
      ? { color: "#27009C", stroke: "#27009C" }
      : { color: "inherit", stroke: "currentColor" };
  };

  const toggleDrawer = () => setDrawerOpen(!drawerOpen);

  return (
    <>
    {!isMdUp && (
        <Stack
          height={"50px"}
          width={"100%"}
          justifyContent={"center"}
          alignItems={"flex-start"}
          pl={3}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
        </Stack>
      )}
      <Drawer
        variant={isMdUp ? "permanent" : "temporary"}
        sx={{
          "& .MuiDrawer-paper": {
            width: "200px",
            overflowY: "auto",
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
            height: "100svh",
            position: "relative",
          },
        }}
        open={isMdUp || drawerOpen}
        onClose={toggleDrawer}
      >
        <Grid
          sx={{ py: 2 }}
          onClick={() => navigate("/")}
        >
          <img src={endlMainLogo} alt="Logo" className="endlLogo" />
        </Grid>
        <List sx={{ flexGrow: 1 }}>
          {navItems.map((item) => (
            <div key={item.text}>
              <ListItem
                button
                component={NavLink}
                to={item.URL}
                sx={{
                  "& .MuiListItemIcon-root": {
                    minWidth: "auto",
                    height: "100%",
                    marginRight: "10px",
                  },
                  "& .MuiListItemText-primary": {
                    fontSize: "13px",
                    fontFamily: "PPMori-Regular",
                  },
                  "&.active": {
                    backgroundColor: "#F6F5F9",
                    borderRight: "2px solid #27009C",
                    fontFamily: "PPMori-Semi-Bold",
                    "& .MuiListItemIcon-root": {
                      color: "#27009C",
                    },
                  },
                }}
                onClick={() => handleExpandItem(item.text)}
                disabled={item.disabled}
              >
                <ListItemIcon
                  sx={{
                    color: getIconStyle(item.URL).color,
                    stroke: getIconStyle(item.URL).stroke,
                  }}
                >
                  {React.cloneElement(item.icon, {
                    style: { stroke: getIconStyle(item.URL).stroke },
                  })}
                </ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItem>
            </div>
          ))}
        </List>

        <List
          sx={{
            marginTop: "20px",
            "& .MuiListItemText-primary": {
              fontSize: "13px",
              fontFamily: "PPMori-Regular",
            },
          }}
        >
          <ListItem
            button
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "auto",
                height: "100%",
                marginRight: "10px",
              },
            }}
            onClick={handleLogoutClick}
          >
            <ListItemIcon>
              <LogoutIcon sx={{ color: "#DD2B22" }} />
            </ListItemIcon>
            <ListItemText primary="Logout" sx={{ color: "#DD2B22" }} />
          </ListItem>
          <Divider />
          <ListItem
            button
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "auto",
                height: "100%",
                marginRight: "10px",
              },
              "& .MuiListItemText-primary": {
                fontSize: "13px",
                fontFamily: "PPMori-Regular",
              },
              "&.active": {
                backgroundColor: "#F6F5F9",
                borderRight: "2px solid #27009C",
                fontFamily: "PPMori-Semi-Bold",
                "& .MuiListItemIcon-root": {
                  color: "#27009C",
                },
              },
            }}
            onClick={() => navigate("/help-center")}
          >
            <ListItemIcon>
              <Help />
            </ListItemIcon>
            <ListItemText primary="Help" />
          </ListItem>
          <Divider />
          <ListItem
            button
            sx={{
              "& .MuiListItemIcon-root": {
                minWidth: "auto",
                marginRight: "10px",
              },
            }}
            onClick={showMyprofile}
          >
            <ListItemIcon>
              <Box
                width={40}
                height={40}
                borderRadius={"100%"}
                overflow={"hidden"}
                sx={{
                  backgroundColor: "#f0f0f0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {userprofile?.profileData?.profileUrl ? (
                  <img
                    src={userprofile?.profileData?.profileUrl}
                    width={20}
                    height={20}
                    style={{ objectFit: "cover" }}
                  />
                ) : (
                  <Typography variant="body1" fontSize={25} color={"#e5e2e0"}>
                    {fullName?.[0]}
                  </Typography>
                )}
              </Box>
            </ListItemIcon>
            <ListItemText
              primary={commonHelpers.capitalizeFirstLetter(fullName)}
              sx={{
                fontFamily: "PPMori-Semi-Bold",
              }}
            />
          </ListItem>
        </List>
      </Drawer>

      <LogoutModal
        isOpen={openLogoutDialog}
        onCLose={handleCloseLogoutDialog}
        onLogout={handleLogout}
      />
    </>
  );
};

export default Navbar;
