import React from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import CurrencyBitcoinOutlinedIcon from "../../Images/git-compare.svg";
import RepeatOutlinedIcon from "../../Images/filters.svg";
import SignUpAccountTypeForm from "../../components/Forms/SignupAccountTypeForm";
import HttpsOutlinedIcon from "../../Images/lock-check.svg";
import LockOpenOutlinedIcon from "../../Images/users-group.svg";
import CorporateFareOutlinedIcon from "../../Images/adjustments-alt.svg";
import GroupsOutlinedIcon from "../../Images/vip.svg";
import StepperUI from "../../components/Common/StepperUI/StepperUI";
import SignUpCreateAccountPersonalForm from "../../components/Forms/SignUpCreateAccountPersonalForm";
import SignUppCompanyInformationForm from "../../components/Forms/SignUppCompanyInformationForm";
import SignUpOTPVerificationForm from "../../components/Forms/SignUpOTPVerificationForm";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "../../Images/circle-check.svg";
import endlMainLogo from "../../Images/brandings/endlMainLogo.png";
const SignUp = ({
  formData,
  formErrors,
  handleInput,
  handleNext,
  handleShowDetailsForm,
  showDetailsForm,
  currentFormStep,
  formSteps,
  changeAccountType,
  otpLength,
  otpExpirationTimer,
  handleResendOTP,
  handleVerifyOTP,
  isVerifyingOTP,
  isCreatingAccount,
  handleGoBack,
  updatingCompanyInfo,
}) => {
  const navigate = useNavigate();
  const isMobileorTablet = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const individualAccountFeatures = [
    {
      feature: "Access to hundred of crypto currencies",
      icon: <img src={RepeatOutlinedIcon} />,
    },
    {
      feature: "Initial individual transaction and open Endl account",
      icon: <img src={CurrencyBitcoinOutlinedIcon} />,
    },
    {
      feature: "Safe & Secure",
      icon: <img src={HttpsOutlinedIcon} />,
    },
  ];
  const businessAccountFeature = [
    {
      feature: "Unlock exclusive feature available only to business users",
      icon: <img src={GroupsOutlinedIcon} />,
    },
    {
      feature: "Setup your organization",
      icon: <img src={CorporateFareOutlinedIcon} />,
    },
    {
      feature: "Invite team members",
      icon: <img src={LockOpenOutlinedIcon} />,
    },
  ];
  return (
    <Box height={"100%"} width={"100%"}>
      <Box
        display={"flex"}
        justifyContent={{ xs: "left", md: "center" }}
        alignItems={"center"}
        px={3}
        py={2}
        position={"relative"}
        minHeight={60}
      >
        {isMobileorTablet && (
          <Box
            position="absolute"
            left={30}
            alignItems={"center"}
            zIndex={1000} // Ensures the logo stays on top
          >
            <img
              src={endlMainLogo}
              alt="Logo"
              style={{ width: 100, height: "auto" }}
            />
          </Box>
        )}
        {showDetailsForm && (
          <Box width={"40%"}>
            <StepperUI steps={formSteps.stepsLabel} step={currentFormStep} />
          </Box>
        )}
        <Box
          position={"absolute"}
          right={25}
          display={"flex"}
          gap={{ xs: 1, sm: 2 }}
          alignItems={"center"}
          flexDirection={{ xs: showDetailsForm ? "column" : "row", sm: "row" }}
        >
          <Typography variant="body1">Have an account?</Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#462A9C",
              color: "#462A9C",
              textTransform: "none",
              "&:disabled": {
                color: "#462A9C",
              },
            }}
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
        </Box>
      </Box>
      <Divider />
      <Grid
        container
        py={5}
        direction={{ xs: "column", md: "column", md: "row" }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          container
          justifyContent={"center"}
          alignItems={{ xs: "center", md: "flex-start" }}
          mb={{ xs: 1, sm: 2, md: 0 }}
          p={{ xs: "0px 20px", sm: 0 }}
        >
          <Box
            display={"flex"}
            width={{ sm: "50%", md: "60%" }}
            gap={3}
            flexDirection={"column"}
          >
            {!showDetailsForm ? (
              <>
                <Typography
                  sx={{ fontSize: { xs: "26px", md: "36px" } }}
                  textAlign={{ md: "start" }}
                >
                  Welcome to endl
                </Typography>
                {formData.userType &&
                  (formData.userType === "INDIVIDUAL" ? (
                    <>
                      <Typography variant="body1" fontSize={16} color={"black"}>
                        An individual account is the best way to <br /> manage
                        your crypto and fiat portfolios
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        gap={2}
                        flexDirection={"column"}
                      >
                        {individualAccountFeatures.map((feature, index) => (
                          <Box
                            display={"flex"}
                            key={`${feature.feature}-${index}`}
                            gap={1}
                            alignItems={"center"}
                          >
                            <Box
                              sx={{
                                border: "1px solid #edecea",
                                borderRadius: "5px",
                                bgcolor: "white",
                                height: 40,
                                width: 40,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexShrink: 0,
                              }}
                            >
                              {feature.icon}
                            </Box>
                            <Typography
                              color={"black"}
                              fontSize={14}
                              sx={{ flexGrow: 1, wordBreak: "break-word" }}
                            >
                              {feature.feature}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="body1"
                        fontSize={16}
                        color={"black"}
                        width={"80%"}
                      >
                        A business account provides companies, institutions, and
                        organizations with access to our suite of professional
                        tools
                      </Typography>
                      <Box
                        display={"flex"}
                        justifyContent={"center"}
                        gap={2}
                        flexDirection={"column"}
                      >
                        {businessAccountFeature.map((feature, index) => (
                          <Box
                            key={`${feature.feature}-${index}`}
                            display={"flex"}
                            gap={1}
                            alignItems={"center"}
                          >
                            <Box
                              sx={{
                                border: "1px solid #edecea",
                                borderRadius: "5px",
                                bgcolor: "white",
                                height: 40,
                                width: 40,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexShrink: 0,
                              }}
                            >
                              {feature.icon}
                            </Box>
                            <Typography
                              color={"black"}
                              fontSize={14}
                              sx={{ flexGrow: 1, wordBreak: "break-word" }}
                            >
                              {feature.feature}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  ))}
              </>
            ) : (
              <Typography sx={{ fontSize: { xs: "26px", md: "36px" } }}>
                {formSteps.stepsHeading[currentFormStep]}
              </Typography>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          {!showDetailsForm ? (
            <Box
              sx={{
                display: { xs: "flex", sm: "flex", md: "block" },
                justifyContent: "center",
              }}
            >
              <Box sx={{ width: { xs: "90%", sm: "50%", md: "100%" } }}>
                <form
                  onSubmit={handleShowDetailsForm}
                  style={{ width: "100%" }}
                >
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    gap={3}
                    width={"100%"}
                  >
                    <Typography
                      fontSize={18}
                      fontWeight={600}
                      color={"#0F0F0F"}
                    >
                      Choose your account type
                    </Typography>
                    <SignUpAccountTypeForm
                      handleInput={changeAccountType}
                      selectedAccountType={formData.userType}
                    />
                    <Button
                      fullWidth
                      type="submit"
                      sx={{
                        backgroundColor: "#462A9C",
                        color: "white",
                        textTransform: "none",
                        height: 40,
                        "&:hover": {
                          backgroundColor: "#462A9C",
                        },
                        "&:disabled": {
                          color: "white",
                        },
                      }}
                      disabled={!formData.userType}
                      onClick={handleShowDetailsForm}
                    >
                      Continue
                    </Button>
                  </Box>
                </form>
              </Box>
            </Box>
          ) : formData.userType === "INDIVIDUAL" ? (
            <IndividualAccountSignUpSteps
              formData={formData}
              formErrors={formErrors}
              currentFormStep={currentFormStep}
              handleNext={handleNext}
              handleInput={handleInput}
              changeAccountType={changeAccountType}
              otpLength={otpLength}
              handleResendOTP={handleResendOTP}
              handleVerifyOTP={handleVerifyOTP}
              otpExpirationTimer={otpExpirationTimer}
              isVerifyingOTP={isVerifyingOTP}
              isCreatingAccount={isCreatingAccount}
              handleGoBack={handleGoBack}
            />
          ) : (
            <BusinessAccountFormSteps
              formData={formData}
              formErrors={formErrors}
              currentFormStep={currentFormStep}
              handleNext={handleNext}
              changeAccountType={changeAccountType}
              handleInput={handleInput}
              otpLength={otpLength}
              handleResendOTP={handleResendOTP}
              handleVerifyOTP={handleVerifyOTP}
              otpExpirationTimer={otpExpirationTimer}
              isVerifyingOTP={isVerifyingOTP}
              isCreatingAccount={isCreatingAccount}
              handleGoBack={handleGoBack}
              updatingCompanyInfo={updatingCompanyInfo}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const IndividualAccountSignUpSteps = ({
  formData,
  formErrors,
  currentFormStep,
  handleNext,
  handleInput,
  changeAccountType,
  otpLength,
  otpExpirationTimer,
  handleResendOTP,
  handleVerifyOTP,
  isVerifyingOTP,
  isCreatingAccount,
  handleGoBack,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: { xs: "90%", sm: "50%", md: "100%" } }}>
        {currentFormStep === 0 && (
          <form onSubmit={handleNext} style={{ width: "100%" }}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography fontSize={18} fontWeight={600}>
                Create your individual account
              </Typography>
              <Typography variant="body1" color={"black"} fontSize={16}>
                Be sure to enter your legal name as it appears on your
                government issued ID.
              </Typography>
              <SignUpCreateAccountPersonalForm
                handleInput={handleInput}
                formData={formData}
                formErrors={formErrors}
              />
              <Button
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: !formData.isCertified ? 'grey' :"#462A9C",
                  color: "white",
                  textTransform: "none",
                  height: 40,
                  "&:hover": {
                    backgroundColor: "#462A9C",
                  },
                  "&:disabled": {
                    color: "white",
                  },
                }}
                onClick={handleNext}
                disabled={!formData.isCertified}
                endIcon={
                  isCreatingAccount && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "white",
                      }}
                    />
                  )
                }
              >
                Continue
              </Button>
              <Typography variant="body1" color={"black"} fontSize={16}>
                Not looking for an individual account?{" "}
                <Typography
                  component={"span"}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  fontWeight={600}
                  fontSize={14}
                  onClick={() => changeAccountType("BUSINESS")}
                >
                  Sign up for a business account
                </Typography>
              </Typography>
            </Box>
          </form>
        )}
        {currentFormStep === 1 && (
          <>
            <SignUpOTPVerificationForm
              otpLength={otpLength}
              handleResendOTP={handleResendOTP}
              handleVerifyOTP={handleVerifyOTP}
              otpExpirationTimer={otpExpirationTimer}
              formData={formData}
              isVerifyingOTP={isVerifyingOTP}
            />
            {/* <Button
              fullWidth
              sx={{
                border: 1,
                borderColor: "#462A9C",
                bgcolor: "white",
                color: "#462A9C",
                textTransform: "none",
                height: 40,
                "&:hover": {
                  bgcolor: "white",
                },
                mt: 1,
              }}
              onClick={handleGoBack}
            >
              Go back
            </Button> */}
          </>
        )}
      </Box>
    </Box>
  );
};

const BusinessAccountFormSteps = ({
  formData,
  formErrors,
  currentFormStep,
  handleNext,
  handleInput,
  changeAccountType,
  otpLength,
  otpExpirationTimer,
  handleResendOTP,
  handleVerifyOTP,
  isVerifyingOTP,
  isCreatingAccount,
  handleGoBack,
  updatingCompanyInfo,
}) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box sx={{ width: { xs: "90%", sm: "50%", md: "100%" } }}>
        {currentFormStep === 0 && (
          <form onSubmit={handleNext}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography fontSize={18} fontWeight={600}>
                Create your business account
              </Typography>
              <Typography fontSize={16} color={"black"}>
                Be sure to enter your legal name as it appears on your
                government issued ID.
              </Typography>
              <SignUpCreateAccountPersonalForm
                handleInput={handleInput}
                formData={formData}
                formErrors={formErrors}
              />
              <Button
                type="submit"
                fullWidth
                sx={{
                  backgroundColor: !formData.isCertified? "grey" : "#462A9C",
                  color: "white",
                  textTransform: "none",
                  height: 40,
                  "&:hover": {
                    backgroundColor: "#462A9C",
                  },
                  "&:disabled": {
                    color: "white",
                  },
                }}
                onClick={handleNext}
                disabled={!formData.isCertified}
                endIcon={
                  isCreatingAccount && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "white",
                      }}
                    />
                  )
                }
              >
                Continue
              </Button>
              <Typography variant="body1" color={"black"} fontSize={16}>
                Not looking for an business account?{" "}
                <Typography
                  component={"span"}
                  sx={{ textDecoration: "underline", cursor: "pointer" }}
                  fontWeight={600}
                  fontSize={14}
                  onClick={() => changeAccountType("INDIVIDUAL")}
                >
                  Sign up for individual account
                </Typography>
              </Typography>
            </Box>
          </form>
        )}
        {currentFormStep === 1 && (
          <form onSubmit={handleNext}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              gap={2}
              width={"100%"}
            >
              <Typography fontWeight={600} fontSize={18} color={"#45434A"}>
                Enter your company information
              </Typography>
              <Typography fontSize={14} color={"#45434A"}>
                When inviting team members or making payment they will be
                associated with this name. You can change it later in the
                setting
              </Typography>
              <SignUppCompanyInformationForm
                handleInput={handleInput}
                formData={formData}
                formErrors={formErrors}
              />
              <Button
                fullWidth
                type="submit"
                sx={{
                  backgroundColor: "#462A9C",
                  color: "white",
                  textTransform: "none",
                  height: 40,
                  "&:hover": {
                    backgroundColor: "#462A9C",
                  },
                  "&:disabled": {
                    color: "white",
                  },
                }}
                onClick={handleNext}
                disabled={updatingCompanyInfo}
                endIcon={
                  updatingCompanyInfo && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "white",
                      }}
                    />
                  )
                }
              >
                Continue
              </Button>
              {/* <Button
                fullWidth
                sx={{
                  border: 1,
                  borderColor: "#462A9C",
                  bgcolor: "white",
                  color: "#462A9C",
                  textTransform: "none",
                  height: 40,
                  "&:hover": {
                    bgcolor: "white",
                  },
                  mt: 1,
                }}
                onClick={handleGoBack}
              >
                Go back
              </Button> */}
            </Box>
          </form>
        )}

        {currentFormStep == 2 && (
          <>
            <SignUpOTPVerificationForm
              otpLength={otpLength}
              handleResendOTP={handleResendOTP}
              handleVerifyOTP={handleVerifyOTP}
              otpExpirationTimer={otpExpirationTimer}
              formData={formData}
              isVerifyingOTP={isVerifyingOTP}
            />
            <Button
              fullWidth
              sx={{
                border: 1,
                borderColor: "#462A9C",
                bgcolor: "white",
                color: "#462A9C",
                textTransform: "none",
                height: 40,
                "&:hover": {
                  bgcolor: "white",
                },
                mt: 1,
              }}
              onClick={handleGoBack}
            >
              Go back
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export const Success = ({
  handleRetry,
  handleLogin,
  formSteps,
  currentFormStep,
  showDetailsForm,
}) => {
  const navigate = useNavigate();
  return (
    <Box height={"100%"} width={"100%"}>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        px={3}
        py={2}
        position={"relative"}
        minHeight={60}
      >
        <Box width={"40%"}>
          <StepperUI steps={formSteps.stepsLabel} step={currentFormStep} />
        </Box>
        <Box
          position={"absolute"}
          right={25}
          display={"flex"}
          gap={2}
          alignItems={"center"}
        >
          <Typography variant="body1">Have an account?</Typography>
          <Button
            variant="outlined"
            sx={{
              borderColor: "#462A9C",
              color: "#462A9C",
              textTransform: "none",
              "&:disabled": {
                color: "#462A9C",
              },
            }}
            onClick={() => navigate("/login")}
          >
            Login
          </Button>
        </Box>
      </Box>
      <Divider />
      <Box>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
          width={"100%"}
          px={2}
          mt={30}
        >
          <img
            src={CheckCircleOutlineIcon}
            style={{
              width: "60px",
              height: "60px",
              display: "block",
              margin: "0 auto",
            }}
          />
          <Typography variant="h4" gutterBottom textAlign="center">
            Email is verified
          </Typography>
          <Typography
            variant="body1"
            textAlign={"center"}
            fontSize={15}
            gutterBottom
          >
            Congrats! You can now use this email to log in to endl.
          </Typography>
          <Button
            sx={{
              backgroundColor: "#462A9C",
              color: "white",
              textTransform: "none",
              height: 40,
              width: "30%",
              mt: 2,
              "&:hover": {
                backgroundColor: "#462A9C",
              },
            }}
            onClick={handleLogin}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default SignUp;
