import { Box, Divider, Typography, Button } from "@mui/material";
import React from "react";

const ReviewDetails = ({
  sendAmount,
  chargesFee,
  walletAddress,
  sourceCurrency,
  handleGoBack,
  handleSubmit,
  handleSendOTP,
  isFetching,
  receiveAmount,
  receiveCurrency,
  selectedPaymentType,
  selectedRecipient,
  chargeTotal
}) => {
  console.log("selectedRecipient" , selectedRecipient)
  return (
    <Box display={"flex"} justifyContent={"center"} padding={5}>
      <Box display={"flex"} flexDirection={"column"} width={"40%"} gap={1}>
        <Typography fontWeight={600} fontSize={22}>Summary</Typography>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          width={"100%"}
          alignItems={"center"}
        >
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"} fontSize={22}>
            Total amount to send
          </Typography>
          <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {sendAmount} {sourceCurrency}
          </Typography>
        </Box>
        {chargesFee.length && chargesFee.map((charges, index) => (
          <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            {charges.chargeName}
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            {charges.chargeAmount} {charges.chargeCurrency}
          </Typography>
        </Box>
        ))}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            Recipient get
          </Typography>
          <Typography fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {receiveAmount} {receiveCurrency}
          </Typography>
        </Box>
        <Box 
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}>
        <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            Total fee amount
          </Typography>
          <Typography  fontFamily="PPMori-Regular" fontWeight={600} fontSize={22}>
            {chargeTotal} USD
          </Typography>
        </Box>
        <Divider />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            Recipient
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            {selectedRecipient?.name ?  selectedRecipient?.name : selectedRecipient.firstName + "  " +selectedRecipient.lastName}
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography fontFamily="PPMori-Regular" color={"rgba(0,0,0,0.60)"}>
            {selectedPaymentType === "cryptoToFiat"
              ? "Bank account"
              : "Wallet address"}
          </Typography>
          <Typography fontFamily="PPMori-Regular" variant="body1">
            {selectedPaymentType === "cryptoToFiat"
              ? selectedRecipient?.address?.region === "EU" ? `${
                  selectedRecipient.bankName
                }...${selectedRecipient.iban?.slice(-4)}` : `${
                  selectedRecipient.bankName
                }...${selectedRecipient.bankAccountNumber?.slice(-4)}`
              : selectedRecipient.walletAddress?.slice(-4)}
          </Typography>
        </Box>
        <Divider />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            Payment method
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            Connected wallet
          </Typography>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          color={"rgba(0,0,0,0.60)"}
        >
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            From
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            MetaMask({walletAddress.slice(0, 6)}...{walletAddress.slice(-4)})
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Typography
            variant="body1"
            fontFamily="PPMori-Regular"
            color={"rgba(0,0,0,0.60)"}
          >
            Source currency
          </Typography>
          <Typography variant="body1" fontFamily="PPMori-Regular">
            {sourceCurrency}
          </Typography>
        </Box>
        <Box width={"100%"} display={"flex"} gap={1}>
          <Button
            variant="outlined"
            color="primary"
            sx={{
              textTransform: "none",
              borderColor: "#462A9C",
              color: "#462A9C",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
            }}
            fullWidth
            onClick={handleGoBack}
          >
            Go back
          </Button>

          <Button
            variant="contained"
            color="primary"
            sx={{
              textTransform: "none",
              backgroundColor: "#462A9C",
              color: "#ffffff",
              fontSize: "12px",
              fontFamily: "PPMori-Regular",
              width: "100%",
              py: 1,
            }}
            onClick={handleSendOTP}
            disabled={sendAmount === 0 || isFetching}
          >
            {" "}
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReviewDetails;
