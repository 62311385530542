import React, { useState } from "react";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./TableComponent.css";
import RenderSkeletonRow from "../LoaderUI/TableLoader";
import EmptyTableComponent from "./EmptyTableComponent";
import dayjs from "dayjs";
const TABLE_HEADING_DATA = [
  // "Recipient name",
  "Transaction ID",
  // "Currency",
  // "Type",
  "Amount",
  "Date Created",
  "Status",
];
const TABLE_DATA = {
  title: "Add transaction",
  recipient: "No transactions yet",
  description:
    "Make a transaction to view the activity here.",
};
const AccountTransactionTable = ({ rows, loading }) => {
  return loading ? (
    <RenderSkeletonRow rowHeadingdata={TABLE_HEADING_DATA} />
  ) : !rows || rows.length === 0 ? (
    <EmptyTableComponent
      tabledata={TABLE_DATA}
      rowHeadingdata={TABLE_HEADING_DATA}
    />
  ) : (
    <FilledTableComponent rows={rows} />
  );
};

const FilledTableComponent = ({ rows }) => {
  return (
    <TableContainer component={Paper} >
      <Table>
        <TableHead
          style={{
            position: "sticky",
            top: "0px",
          }}
        >
          <TableRow>
            {TABLE_HEADING_DATA.map((heading, index) => (
              <TableCell
                sx={{
                  borderRight: "1px solid #ccc",
                  backgroundColor: "#F6F5F9",
                  fontWeight: "bold",
                  fontFamily: "PPMori-Regular",
                  fontSize: "14px",
                }}
                key={`${index}-${heading}`}
              >
                {heading}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((transaction) => {
            const date = dayjs(transaction.created_date).format('DD MM, YYYY');
            const minutes = dayjs(transaction.created_date).format('HH:mm:ss');
            return <TableRow key={transaction.id}>
              {/* <TableCell className="tablecell">
                {transaction.recipient_name}
              </TableCell> */}
              <TableCell className="tablecell">
                {transaction.transaction_id}
              </TableCell>
              {/* <TableCell className="tablecell">
                {transaction.currency}
              </TableCell> */}
              {/* <TableCell className="tablecell">
                {transaction.type || "-"}
              </TableCell> */}
              <TableCell className="tablecell">{transaction.amount?.toLocaleString()}</TableCell>
              <TableCell className="tablecell">
                {date}<br></br>
                {minutes}
              </TableCell>
              <TableCell className="tablecell">
                {" "}
                <span
                  className={
                    transaction.status == "INITIATED" ||
                    transaction.status == "PENDING"
                      ? "status-pending"
                      : transaction.status == "REJECTED" ||
                        transaction.status == "FAILED"
                      ? "status-failed"
                      : transaction.status == "COMPLETE"
                      ? "status-active"
                      : "status-active"
                  }
                >
                  {transaction.status}
                </span>
              </TableCell>
            </TableRow>
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AccountTransactionTable;
