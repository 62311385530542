import React, { useEffect, useLayoutEffect, useState } from "react";
import { Modal, Box, Typography, Button, IconButton } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import CloseIcon from "@mui/icons-material/Close";
import AddRecipientBankAccountForm from "../Forms/RecipientBankForm/AddRecipientBankAccountForm";
import {
  addRecipients,
  updateRecipient,
} from "../../lib/service/RecipientsApi/RecipientsApi";
import AddRecipientCryptoWalletForm from "../Forms/AddRecipientCryptoWalletForm";
import { notify } from "../Notification/Notification";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  getCountry,
  getCurrency,
} from "../../lib/service/currencyAndCoutryApi/currencyAndCountryApi";
import dayjs from "dayjs";
import {
  isValidRecipientBankFormData,
  prepareBankRecipientData,
} from "../../utils/recipeint.helpers";
import commonHelpers from "../../utils/common.helpers";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  maxHeight: "90vh",
  overflow: "auto",
  px: { xs: 3, sm: 5 },
  py: { xs: 2, sm: 3 },
  display: "flex",
  flexDirection: "column",
  gap: 3,
  borderRadius: 5,
  width: { xs: "90%", sm: "600px"},
  overflow: 'auto',
};

const AddAndEditManualRecipientModal = ({
  open,
  onClose,
  mode = "create",
  addNewRecipient,
  provideReceiveMode,
  editRecipientData,
  onUpdateRecipient,
}) => {
  const [step, setStep] = useState(1);

  const [receiveMode, setReceiveMode] = useState("BANK_ACCOUNT"); // As we are working on crypto to fiat only - so add recipient only for now Bank account by default later we will add other options as well

  useLayoutEffect(() => {
    if (mode === "edit") {
      setReceiveMode(editRecipientData.receiveMode);
      setStep(2);
    }
  }, [mode]);

  useLayoutEffect(() => {
    if (provideReceiveMode) {
      setReceiveMode(provideReceiveMode);
      setStep(2);
    }
  }, [provideReceiveMode]);

  const handleBack = () => {
    if (step === 1) {
      return;
    }
    setStep((prev) => prev - 1);
  };
  const submitHandler = async (data) => {
    if (mode === "create") {
      try {
        const res = await addRecipients({
          ...data,
          receiveMode: receiveMode,
        });
        if(res?.code === 200){
          addNewRecipient(res?.data);
          notify.success({
            message: "New recipient is added",
          });
          onClose();
        }
      } catch (error) {
        if (error?.errors?.length > 0) {
          error.errors.map((err) =>
            notify.error({
              message: err.errMsg,
              description: err.errDesc,
            })
          );
        } 
      }
    } else {
      try {
        const res = await updateRecipient({
          ...data,
          receiveMode: receiveMode,
          id: editRecipientData.id,
        });
        if(res.code === 200) {
          onUpdateRecipient(res.data);
          notify.success({
            message: "Recipient is updated",
          });
          onClose();
        }
      } catch (error) {
        if (error?.errors?.length > 0) {
          error.errors.map((err) =>
            notify.error({
              message: err.errMsg,
              description: err.errDesc,
            })
          );
        }
      }
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style }} className="hide-scrollbar">
        <Box position={"relative"} width="100%">
          {!provideReceiveMode && mode !== "edit" && step > 1 && (
            <IconButton
              onClick={handleBack}
              sx={{ position: "absolute", left: -32, top: -3 }}
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>
          )}
          <Typography variant="h5" display={"flex"}>
            {mode === "create" ? "Add new recipient" : "Update recipient"}
          </Typography>
          <IconButton
            onClick={onClose}
            aria-label="close-open-account-modal"
            sx={{ position: "absolute", right: -5, top: -10 }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {step == 1 && (
          <>
            <Box display={"flex"} flexDirection={"column"} gap={3}>
              <Typography variant="body1" fontWeight={600}>
                What details for the recipient do you want to add ?
              </Typography>
              <TypeOfRecipientReceiveMode
                selectedReceiveMode={receiveMode}
                handleChangeReceiveMode={(receiveMode) =>
                  setReceiveMode(receiveMode)
                }
              />
            </Box>
            <ActionController
              disabled={!receiveMode}
              onAction={() => setStep(2)}
              onCancel={onClose}
              actionText={"Continue"}
            />
          </>
        )}
        {step == 2 &&
          (receiveMode === "BANK_ACCOUNT" ? (
            <BankRecipientController
              mode={mode}
              handelSubmit={submitHandler}
              onClose={onClose}
              editRecipientData={editRecipientData}
            />
          ) : (
            // <CryptoRecipientController mode={mode} handleSubmit={submitHandler} onClose={onClose}/> // As we only need the bank recipient for now
            <></>
          ))}
      </Box>
    </Modal>
  );
};

const TypeOfRecipientReceiveMode = ({
  selectedReceiveMode,
  handleChangeReceiveMode,
}) => {
  function StyledRadio(props) {
    return (
      <Radio
        color="primary"
        sx={{
          "&.Mui-checked": {
            color: "#462A9C", 
          },
        }}
        {...props}
      />
    );
  }

  return (
    <FormControl style={{ marginBottom: "1rem" }}>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="receiveMode"
        onChange={(e) => handleChangeReceiveMode(e.target.value)}
        value={selectedReceiveMode}
      >
        <Box
          sx={{
            border: "1px solid",
            borderColor:
              selectedReceiveMode === "CRYPTO_WALLET" ? "#c6c5c4" : "#edebe9",
            background: "#fcfbfa",
            mb: 2,
            width: "100%",
            borderRadius: "5px",
            padding: 1,
            cursor: "pointer",
          }}
          // onClick={() => handleChangeReceiveMode("CRYPTO_WALLET")}
        >
          <FormControlLabel
            disabled
            value="CRYPTO_WALLET"
            name="receiveMode"
            control={<StyledRadio />}
            label="Crypto Wallet Address"
            onClick={(e) => e.stopPropagation()}
          />
        </Box>
        <Box
          sx={{
            border: "1px solid",
            borderColor:
              selectedReceiveMode === "BANK_ACCOUNT" ? "#c6c5c4" : "#edebe9",
            background: "#fcfbfa",
            mb: 2,
            width: "100%",
            borderRadius: "5px",
            padding: 1,
            cursor: "pointer",
          }}
          onClick={() => handleChangeReceiveMode("BANK_ACCOUNT")}
        >
          <FormControlLabel
            value="BANK_ACCOUNT"
            name="receiveMode"
            control={<StyledRadio />}
            label="Bank Details"
            onClick={(e) => e.stopPropagation()}
          />
        </Box>
      </RadioGroup>
    </FormControl>
  );
};

const BankRecipientController = ({
  mode,
  handelSubmit,
  onClose,
  editRecipientData,
}) => {
  const [bankFormData, setBankFormData] = useState({
    type: null,
    alias: null,
    addressLine1: null,
    addressLine2: null,
    city: null,
    state: null,
    country: null,
    postalCode: null,
    currency: null,
    email: null,
    bankAccountNumber: null,
    bankRoutingNumber: null,
    bankName: null,
    firstName: null,
    lastName: null,
    phone: null,
    dateOfBirth: dayjs("12-05-1999", "DD-MM-YYYY"),
    iban: null,
    accountType: null,
    relationshipToCustomer: null,
    ifsc: null,
    swiftBic: null,
    sortCode: null,
    region: null,
  });
  const [bankFormErrors, setBankFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [tempCurrencyList, setTempCurrencyList] = useState([]);
  const fetchCountryList = React.useCallback(async () => {
    try {
      let data ={
        opertionType : 'RECIPIENT'
      }
      const response = await getCountry(data);
      setCountryList(response.data.countries.filter((val) => val.inAllowed)?.sort((a, b) => {
        return a.name.localeCompare(b.name);
      }));
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);
  const fetchCurrencyList = React.useCallback(async () => {
    try {
      const response = await getCurrency({});
      setCurrencyList(
        response.data.destination.currency
      );
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);

  useEffect(() => {
    fetchCountryList();
    fetchCurrencyList();
  }, []);
 
  useEffect(() => {
    if (mode === "edit") {
      let formData = {
        type: editRecipientData.type,
        alias: editRecipientData.alias,
        firstName: commonHelpers.capitalizeFirstLetter(editRecipientData.firstName || editRecipientData?.name?.split(" ")[0]),
        lastName: commonHelpers.capitalizeFirstLetter(editRecipientData.lastName || editRecipientData?.name?.split(" ")[1]),
        phone: editRecipientData.phone,
        email: editRecipientData.email,
        dateOfBirth: editRecipientData.dateOfBirth
          ? dayjs(editRecipientData.dateOfBirth)
          : dayjs("12-05-1999", "DD-MM-YYYY"),
        addressLine1: editRecipientData.address.addressLine1 ?? null,
        addressLine2: editRecipientData.address.addressLine2 ?? null,
        city: editRecipientData.address.city ?? null,
        state: editRecipientData.address.state ?? null,
        country: editRecipientData.address.countryId
          ? countryList.find(
              (country) => country.id === editRecipientData.address.countryId
            )
          : null,
        postalCode: editRecipientData.address.postalCode ?? null,
        currency: editRecipientData.currency
          ? currencyList.find(
              (currency) => currency.id === editRecipientData.currencyId
            )
          : null,
        relationshipToCustomer:
          editRecipientData.relationshipToCustomer ?? null,
        ifsc: editRecipientData.ifsc ?? null,
        swiftBic: editRecipientData.swiftBic ?? null,
        sortCode: editRecipientData.sortCode ?? null,
        accountType: editRecipientData.accountType ?? null,
        iban: editRecipientData.iban ?? null,
        bankName: editRecipientData.bankName,
        bankAccountNumber: editRecipientData.bankAccountNumber,
        bankRoutingNumber: editRecipientData.bankRoutingNumber,
        region: editRecipientData.address.countryId
        ? countryList.find(
            (country) => country.id === editRecipientData.address.countryId
          )?.region
        : null
      };
      if(editRecipientData.currency && currencyList){
        const data = [currencyList.find(
          (currency) => currency.id === editRecipientData.currencyId
        )].filter(fd => fd) || [];
        setTempCurrencyList(data);
      }
      setBankFormData(formData);
    }
  }, [mode, currencyList, countryList]);

  const handleChange = (name, value) => {
    const error = { ...bankFormErrors };
    if (error[name]) delete error[name];
    setBankFormErrors(error);
    if (name === "country") {
      const selectedCountry = countryList.find(fd => fd.id === value);
      setBankFormData((prev) => ({
        ...prev,
        [name]: selectedCountry,
        ifsc: editRecipientData?.ifsc ?? null,
        swiftBic: editRecipientData?.swiftBic ?? null,
        sortCode: editRecipientData?.sortCode ?? null,
        accountType: editRecipientData?.accountType ?? null,
        iban: editRecipientData?.iban ?? null,
        bankName: editRecipientData?.bankName,
        bankAccountNumber: editRecipientData?.bankAccountNumber,
        bankRoutingNumber: editRecipientData?.bankRoutingNumber,
        currency: null,
        region: selectedCountry.region ?? null
      }));
      if(selectedCountry && selectedCountry?.region === "EU") {
        let data = {}; 
        if(selectedCountry.code_iso_three === "GBR") {
          data = currencyList.find(fd => fd.countryCode === selectedCountry.code_iso_three);
        } else {
          data  = currencyList.find(fd => fd.code === "EUR");
        }
        setTempCurrencyList([data]);
        return;
      }
      if([null,undefined,""].includes(selectedCountry.region)){
        setTempCurrencyList(currencyList.filter(fd => fd.countryCode === selectedCountry.code_iso_three && fd.destinationActive));
      }
      return;
    }
    if (name === "currency") {
      setBankFormData((prev) => ({
        ...prev,
        [name]: currencyList.find((currency) => currency.id === value),
      }));
      return;
    }
    setBankFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isValidInput = () => {
    const { isValid, errors } = isValidRecipientBankFormData(
      bankFormData,
      bankFormErrors
    );
    setBankFormErrors(errors);
    return isValid;
  };

  const submit = async () => {
    if (!isValidInput()) return;
    setIsLoading(true);
    let data = {
      type: bankFormData.type?.trim(),
      alias: bankFormData.alias?.trim(),
      address: {
        addressLine1: bankFormData.addressLine1.trim(),
        addressLine2: bankFormData.addressLine2?.trim() || null,
        city: bankFormData.city?.trim(),
        state: bankFormData.state?.trim(),
        postalCode: bankFormData.postalCode?.trim(),
        country: bankFormData.country?.name.trim(),
        countryId: bankFormData.country?.id,
        region: bankFormData.region ?? null,
      },
      currency: bankFormData.currency?.code?.trim() || null,
      currencyId: bankFormData.currency?.id,
      email: bankFormData.email?.trim() || null,
      bankName: bankFormData.bankName?.trim() || null,
      firstName: bankFormData.firstName?.trim() || null,
      lastName: bankFormData.lastName?.trim(),
      phone: bankFormData.phone?.trim() || null,
      dateOfBirth: bankFormData.dateOfBirth?.format("DD-MM-YYYY") || null,
      relationshipToCustomer: bankFormData.relationshipToCustomer || null,
      ...prepareBankRecipientData(bankFormData),
    };

    await handelSubmit(data);
    setIsLoading(false);
  };

  return (
    <>
      <AddRecipientBankAccountForm
        formData={bankFormData}
        formErrors={bankFormErrors}
        handleChange={handleChange}
        currencyList={tempCurrencyList}
        countryList={countryList}
        isEdit={mode==="edit" ? true : false}
      />
      <ActionController
        isLoading={isLoading}
        disabled={isLoading}
        onAction={submit}
        onCancel={onClose}
        actionText={
          mode === "edit"
            ? isLoading
              ? "Saving recipient"
              : "Save recipient"
            : isLoading
            ? "Adding recipient"
            : "Add recipient"
        }
      />
    </>
  );
};

const CryptoRecipientController = ({
  mode,
  handleSubmit,
  onClose,
  editRecipientData,
}) => {
  const [cryptoWalletFormData, setCryptoFormData] = useState({
    type: "",
    walletChain: "",
    firstName: "",
    walletAddress: "",
    email: "",
  });

  const [cryptoWalletFormErrors, setCryptoWalletFormErrors] = useState({
    type: null,
    walletChain: null,
    firstName: null,
    walletAddress: null,
    email: null,
  });
  useEffect(() => {
    setCryptoFormData({
      type: editRecipientData.type,
      walletChain: editRecipientData.walletChain,
      firstName: editRecipientData.firstName,
      walletAddress: editRecipientData.walletAddress,
      email: editRecipientData.email,
    });
  }, [mode]);
  const handleChange = (e) => {
    setCryptoFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    setCryptoWalletFormErrors((prev) => ({
      ...prev,
      [e.target.name]: null,
    }));
  };
  const [isLoading, setIsLoading] = useState(false);
  const isValidInput = () => {
    let isValid = true;

    const errors = { ...cryptoWalletFormErrors };
    if (!cryptoWalletFormData.type || cryptoWalletFormData.type === "") {
      errors.type = "Recipient type is required";
      isValid = false;
    }
    if (
      !cryptoWalletFormData.walletAddress ||
      !cryptoWalletFormData.walletAddress === ""
    ) {
      errors.walletAddress = "Wallet address is required";
      isValid = false;
    }
    if (
      !cryptoWalletFormData.walletChain ||
      cryptoWalletFormData.walletChain === ""
    ) {
      errors.walletChain = "Chain is required";
      isValid = false;
    }
    if (
      !cryptoWalletFormData.firstName ||
      cryptoWalletFormData.firstName === ""
    ) {
      errors.firstName = "Name of the recipient is required";
      isValid = false;
    }
    setCryptoWalletFormErrors(errors);

    return isValid;
  };

  const submit = async () => {
    if (!isValidInput()) return;
    setIsLoading(true);
    await handleSubmit(cryptoWalletFormData); // later may have to change thr format
    setIsLoading(false);
  };

  return (
    <>
      <AddRecipientCryptoWalletForm
        formData={cryptoWalletFormData}
        handleChange={handleChange}
        formErrors={cryptoWalletFormErrors}
      />
      <ActionController
        disabled={isLoading}
        onAction={submit}
        onCancel={onClose}
        actionText={
          mode === "edit"
            ? isLoading
              ? "Saving recipient"
              : "Save recipient"
            : isLoading
            ? "Adding recipient"
            : "Add recipient"
        }
      />
    </>
  );
};

const ActionController = ({ disabled, onAction, onCancel, actionText }) => {
  return (
    <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} gap={5}>
      <Button
        variant="text"
        color="primary"
        sx={{
          color: "#462A9C",
          fontSize: { xs: 12, sm: 14 },
          textTransform: "none",
          py: 1,
        }}
        onClick={onCancel}
      >
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        sx={{
          backgroundColor: "#462A9C",
          color: "#ffffff",
          fontSize: { xs: 12, sm: 14 },
          textTransform: "none",
          py: 1,
        }}
        disabled={disabled}
        onClick={onAction}
      >
        {actionText}
      </Button>
    </Box>
  );
};

export default AddAndEditManualRecipientModal;
