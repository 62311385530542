import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  FormLabel,
  IconButton,
  Paper,
  FormHelperText,
  Stack,
  Tooltip
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Plus } from "../../Images/Plus.svg";
import { styled } from "@mui/system";
import {
  getTranscation,
  getCurrency,
  getQuote,
  transcation,
} from "../../lib/service/DashboardApi/dashboardApi";
import { getProfile } from "../../lib/service/ProfileApi/profileApi";
import { getAllTranscation } from "../../lib/service/TranscationApi/transcationApi";
import DashboardTransactionTable from "../../components/TableComponent/DashboardTransactionTable";
import "./Dashboard.css";
import ConnectWallet from "../../container/DashboardContainer/ConnectWallet";
import { useAccount, useBalance } from "wagmi";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import PopoverList from "../../components/Popover/PopoverList";
import SendMoneyModal from "../../components/ModalComponent/SendMoneyModal/SendMoneyModal";
import { ReactComponent as Send2 } from "../../Images/Send2.svg";
import CardLoader from "../../components/LoaderUI/CardLoaderFax";
import VerificationNotification from "../../components/NotificationBar/VerificationNotification";
import VerificationModal from "../../components/NotificationBar/VerificationModal";
import { useProfile } from "../../context/ProfileContext";
import { getAllAccountsList } from "../../lib/service/accountsApi/accountsApi";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AddCardIcon from '@mui/icons-material/AddCard';
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import OpenAccountModal from "../../components/ModalComponent/OpenAccountModal";
import InternalTransferModal from "../../components/ModalComponent/InternalTransfarModal/InternalTransferModal";
import AddAndEditManualRecipientModal from "../../components/ModalComponent/AddAndEditManualRecipientModal";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/slices/userSlice";
import ChargesModal from "../../components/ModalComponent/CurrencyCalculator/ChargesModal";

const toCamelCase = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const AddRecipientButton = styled(Button)({
  backgroundColor: "white",
  color: "#462A9C",
  textTransform: "none",
  fontFamily: "PPMori-Regular",
  "&:hover": {
    backgroundColor: "white",
  },
});

const SendMoneyButton = styled(Button)({
  backgroundColor: "#462A9C",
  color: "#FFFFFF",
  textTransform: "none",
  fontFamily: "PPMori-Regular",
  "&:hover": {
    backgroundColor: "#462A9C",
  },
});



const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const flagImages = importAll(
  require.context("../../Images/flags", false, /\.(png)$/)
);

const usdcToUsdConversionRate = 0.9991;
const usdtToUsdConversionRate = 1.0;

const Dashboard = () => {
  const [sendAmount, setSendAmount] = useState("");
  const [depositeType, setDepositeType] = useState('BANK_TRANSFER')
  const [receiveAmount, setReceiveAmount] = useState("");
  const [amountValid, setAmountValid] = useState("");
  const [sendCurrency, setSendCurrency] = useState("USDC");
  const [receiveCurrency, setReceiveCurrency] = useState("INR");
  const [transactions, setTransactions] = useState([]);
  const [cardDetails, setCardDetails] = useState([
    {
      title: "Combined balance: crypto + fiat",
      amount: "0.00",
      updateInfo: "Live update",
    },
    { title: "Last payment", amount: "0.00", updateInfo: "24 Jan, 2024" },
    {
      title: "Transactions this month",
      amount: "0",
      updateInfo: "1 Jan - 24 Jan",
    },
    { title: "Pending transactions", amount: "0", updateInfo: "Live update" },
  ]);
  const [showTransactions, setShowTransactions] = useState(false);
  // const [recipent, setRecipent] = useState([]);
  const [sourceRegularCurrencies, setSourceRegularCurrencies] = useState([]);
  const [destinationRegularCurrencies, setDestinationRegularCurrencies] =
    useState([]);
  const [transactionsList, setTransactionsList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(true);
  const [loadingQuote, setLoadingQuote] = useState(false);
  const [chargeTotal, setchargeTotal] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showVerification, setShowVerification] = useState(null);
  const [showVerificationLoader, setshowVerificationLoader] = useState(false);
  const [userType, setUserType] = useState('');
  // const [countryList, setCountryList] = useState([]);
  const [usdcBalance, setUsdcBalance] = useState(null);
  const [usdtBalance, setUsdtBalance] = useState(null);
  const [showQuoteError, setShowQuoteError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { address, isConnected } = useAccount();
  const { data } = useBalance({
    address: address,
  });
  const [addRecipientsPopoverEle, setaAddRecipientsPopoverEle] = useState(null);
  const [openSendMoneyModal, setOpenSendMoney] = useState(false);
  const [kycInfo, setkycInfo] = useState({});
  const [addRecipientModalOpen, setAddRecipientModalOpen] = useState(false);
  const { profile, fullName, previewUrl } = useProfile();
  const getuserTranscation = useCallback(async () => {
    try {
      const response = await getTranscation();
      if (response.data) {
        setTransactions(response.data);
      }
    } catch (error) {
      console.log("Error Object", error);
    }
  }, []);

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { source, destination } = response.data;
      setSourceRegularCurrencies(source.currency);
      setDestinationRegularCurrencies(destination.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);

  const getAllTranscationList = useCallback(async () => {
    try {
      const data = {
        page: 0,
        size: 5,
        dateRange: "ALL_TIME",
        sortBy: "createdOn,desc",
        endlTransactionMode: [
          "CRYPTO_TO_FIAT"
        ]
      }
      const response = await getAllTranscation(data);
      if (response.data && response.data["txns"].length) {
        setTransactionsList(response.data["txns"]);
        setLoading(false);
        setShowTransactions(true);
      } else {
        setTransactionsList([]);
      }
    } catch (error) {
      console.log("Error fetching transactions:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  const [exchangeRate, setExchangeRate] = useState("");
  const [chargesFee, setChargesFees] = useState([]);
  const [accountsData, setAccountsData] = useState(null);
  const [showTotalAccountBalance, setShowTotalAccountBalance] = useState(true);
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [internalTransferModalOpen, setInternalTransferModalOpen] = useState(false);
  const [showCharges, setshowCharges] = useState(false);
  const [isAccountOpenLoading, setIsAccountOpenLoading] = useState(false);

  const showCombinedBalance = (transactions) => {
    const totalBalance = transactions.totalBalance ? parseFloat(transactions.totalBalance).toFixed(2) : '0 USD';
    const usdcBalanceUsd = usdcBalance != null ? parseFloat(usdcBalance * usdcToUsdConversionRate).toFixed(2) : 0;
    const usdtBalanceUsd = usdtBalance != null ? parseFloat(usdtBalance * usdtToUsdConversionRate).toFixed(2) : 0;
    const combinedTotal = (parseFloat(usdcBalanceUsd) + parseFloat(usdtBalanceUsd) + parseFloat(totalBalance)).toFixed(2);

    return isConnected ? `${combinedTotal} USD` : totalBalance;
  }

  const debounce = (func, wait) => {
    let timeout;
    return function executedFunction(...args) {
      const later = () => {
        clearTimeout(timeout);
        func(...args);
      };
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
    };
  };
  const fetchQuote = useCallback(
    debounce(async (amount, sendCurrency, receiveCurrency) => {
      setLoadingQuote(true);
      try {
        const response = await getQuote({
          partnerId: "1112",
          depositType: 'CRYPTO_WALLET',
          accountType: "INDIVIDUAL",
          source: {
            amount: Number(amount),
            currency: sendCurrency,
          },
          destination: {
            currency: receiveCurrency,
          },
        });

        const data = response.data;

        if (response.code === 200) {
          const { source, destination, fxRate, charges, chargeTotal } = response.data;
          setExchangeRate(fxRate);
          setChargesFees(charges);
          setReceiveAmount(destination.amount)
          setchargeTotal(chargeTotal)
          setShowQuoteError("")
        } else {
          console.error("GetQuote failed with status:", response.errors[0]['errDesc']);
          setExchangeRate(0);
          setReceiveAmount('');
          setChargesFees([]);
          setchargeTotal(0);
          setShowQuoteError(response.errors[0]['errDesc'])
        }
      } catch (error) {
        console.error("Error fetching quote:", error);
        setExchangeRate(null);
        setChargesFees([]);
        setReceiveAmount(null);
        setShowQuoteError(error.errors[0]['errDesc'])
      } finally {
        setLoadingQuote(false);
      }
    }, 300),
    []
  );

  useEffect(() => {
    getuserTranscation();
    fetchCurrencyList();
    getAllTranscationList();
    fetchProfileData();
    fetchAllAccounts();
  }, []);

  const fetchProfileData = useCallback(async () => {
    try {
      setshowVerificationLoader(true);
      const response = await getProfile();
      if (response.data) {
        const { kycInfo, userType } = response.data;
        setkycInfo(kycInfo);
        setUserType(userType);
        setshowVerificationLoader(false);
        if (kycInfo.showNotification) {
          setShowVerification(true);
        } else {
          setShowVerification(false);
        }
      }
    } catch (error) {
      setshowVerificationLoader(false);
      console.error("Error fetching profile data:", error);
    }
  }, []);

  // Update card details with API response data
  useEffect(() => {
    if (transactions) {
      const updatedCardDetails = [
        {
          title: "Combined balance: crypto + fiat",
          amount: showCombinedBalance(transactions),
          updateInfo: "Live update",
        },
        {
          title: "Last payment",
          amount: transactions.lastTxAmount ? `${transactions.lastTxAmount} ${transactions?.lastTxCurrency || "USDC"}` : `0 ${transactions?.lastTxCurrency || "USDC"}`,
          updateInfo: transactions.lastTxDate ?? "",
        },
        {
          title: "Transactions this month",
          amount: transactions.txCountThisMonth ? transactions.txCountThisMonth : 0,
          updateInfo: transactions.thisMonthDateRange ?? "",
        },
        {
          title: "Pending transactions",
          amount: transactions?.pendingTxCount ? transactions.pendingTxCount : 0,
          updateInfo: "Live update",
        },
      ];
      setCardDetails(updatedCardDetails);
    }
    dispatch(userActions.setProfileData({
      usdt: usdcBalance,
      usdc: usdtBalance,
    }));
  }, [transactions, isConnected, usdcBalance, usdtBalance]);

  const handleSendAmountChange = (event) => {
    const amount = event.target.value;
    const numericRegex = /^[0-9]*$/;
    if (!numericRegex.test(amount)) {
      setAmountValid("Only numeric characters are allowed.");
      return;
    }
    if (!amount) {
      setExchangeRate(0);
      setReceiveAmount('');
      setChargesFees([]);
      setchargeTotal(0);
    }
    setSendAmount(amount);
    if (sendCurrency && receiveCurrency) {
      fetchQuote(amount, sendCurrency, receiveCurrency);
    }

  };

  const handleReciveAmountChange = (event) => {
    const amount = event.target.value;
    setSendAmount(amount);
  };

  const handleSendCurrencyChange = (event) => {
    setSendCurrency(event.target.value);
  };

  const handleReceiveCurrencyChange = (event) => {
    const value = event.target.value;
    setReceiveCurrency(value);
    fetchQuote(sendAmount, sendCurrency, value);
  };

  const toggleAddRecipientModal = () => setAddRecipientModalOpen(!addRecipientModalOpen);

  const AddRecipientsPopoverOptions = [
    {
      label: "Add recipient manually",
      onClick: toggleAddRecipientModal,
      icon: <PersonAddAltOutlinedIcon />,
    },
  ];

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchAllAccounts = async () => {
    try {
      const res = await getAllAccountsList();
      setAccountsData(res.data);
    } catch (error) {
      console.log(`Error while fetching accounts in dashboard`, error)
    }
  }

  const onAccountOpen = async () => {
    setIsAccountOpenLoading(true);
    try {
      navigate('/accounts');
    } catch (error) {
      console.log(error);
    } finally {
      setIsAccountOpenLoading(false);
    }
  };
  return (
    <>
      {openSendMoneyModal && (
        <SendMoneyModal
          open={openSendMoneyModal}
          onClose={() => {
            setOpenSendMoney(false);
            getuserTranscation();
            getAllTranscationList();
          }}
          addNewTransaction={() => getAllTranscationList()}
        />
      )}
      {addRecipientModalOpen && (
        <AddAndEditManualRecipientModal
          open={addRecipientModalOpen}
          onClose={toggleAddRecipientModal}
          addNewRecipient={(data) => navigate("/recipients")}
        />
      )}
      <Box sx={{ mt: 0, mb: 4 }}>
        <Box
          display={"flex"}
          flexDirection={"column"}
          padding={3}
          gap={2}
          sx={{ backgroundColor: "#F6F5F9" }}
        >
          {kycInfo &&
            Object.keys(kycInfo).length > 0 &&
            !showVerificationLoader &&
            !showVerification && (
              <>
                <VerificationNotification
                  statusLink={kycInfo}
                  onOpenModal={handleOpenModal}
                />
                <VerificationModal
                  open={isModalOpen}
                  statusLink={kycInfo}
                  onClose={handleCloseModal}
                  fetchProfileData={fetchProfileData}
                  userType={userType}
                />
              </>
            )}
          <Typography
            variant="h3"
            fontSize="26px"
            style={{ color: "#0F0F0F", fontFamily: "PPMori-Regular" }}
          >
            Welcome, {toCamelCase(profile.firstName)}
          </Typography>
          <Stack
            spacing={2}
            flexDirection={{ xs: 'column', lg: 'row' }}
            alignItems={{ xs: 'flex-start', lg: 'center' }}
            sx={{
              fontSize: '0.75rem',
              padding: '6px',
              gap: { xs: 2, sm: 2 },
            }}
          >
            <Stack direction={{ xs: "column", sm: "row"}} spacing={2} width={{ xs: "100%", lg: "70%" }}>
              {cardDetails.map((card, index) => (
                <Box
                  key={index}
                  display="flex"
                  gap={2}
                  sx={{ minWidth: { xs: "100%", sm: "9.375rem" } }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Typography fontSize="0.875rem" color="#0F0F0F">
                      {card.title}
                    </Typography>
                    <Typography color="#0F0F0F" fontSize="1.125rem">
                      {card.amount}
                    </Typography>
                    <Typography color="#0F0F0F" fontSize="0.75rem">
                      {card.updateInfo}
                    </Typography>
                  </Box>
                  <Divider orientation="vertical" />
                </Box>
              ))}
            </Stack>

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} alignItems="center" width={{ xs: "100%", lg: "30%" }}>
              <AddRecipientButton
                variant="outlined"
                color="primary"
                disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                startIcon={
                  <Plus
                    style={{
                      color: profile?.kycInfo?.kycStatus !== "COMPLETED" ? "grey" : "inherit",
                      stroke: profile?.kycInfo?.kycStatus !== "COMPLETED" ? "grey" : "#462A9C",
                    }}
                  />
                }
                onClick={(e) => setaAddRecipientsPopoverEle(e.currentTarget)}
                sx={{ width: { xs: "100%" } }}
              >
                Add recipient
              </AddRecipientButton>
              <PopoverList
                open={Boolean(addRecipientsPopoverEle)}
                anchorEl={addRecipientsPopoverEle}
                handleClose={() => setaAddRecipientsPopoverEle(null)}
                options={AddRecipientsPopoverOptions}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              />
              <SendMoneyButton
                variant="contained"
                startIcon={<Send2 />}
                disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                onClick={() => setOpenSendMoney(true)}
                sx={{ width: { xs: "100%" } }}
              >
                Send money
              </SendMoneyButton>
            </Stack>
          </Stack>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            padding: 2.5,
            flexDirection: { xs: "column", sm: "row", md: "row" },
          }}
          display={"flex"}
          alignItems={"center"}
          width={"100%"}
          gap={2}
        >
          <Paper
            variant="outlined"
            sx={{
              height: "350px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              padding={2}
            >
              <Box>
                <Typography
                  variant="h6"
                  gutterBottom
                  fontSize="13px"
                  fontFamily="PPMori-Regular"
                >
                  Stablecoin balance
                </Typography>
                <Typography
                  variant="h5"
                  fontSize="18px"
                  fontFamily="PPMori-Regular"
                >
                  {profile?.kycInfo?.kycStatus === "COMPLETED" && data ? (
                    <>
                      {usdtBalance !== null && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={require("../../Images/usdt.png")}
                            alt="USDC"
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "8px",
                            }}
                          />
                          <span>USDT: {usdcBalance?.toLocaleString()}</span>
                        </div>
                      )}
                      {usdcBalance !== null && (
                        <div>
                          <img
                            src={require("../../Images/usdc.png")}
                            alt="USDC"
                            style={{
                              width: "15px",
                              height: "15px",
                              marginRight: "8px",
                            }}
                          />
                          <span>USDC: {usdtBalance?.toLocaleString()}</span>
                        </div>
                      )}
                    </>
                  ) : (
                    "0"
                  )}
                </Typography>
              </Box>
              {profile?.kycInfo?.kycStatus === "COMPLETED" && (
                <ConnectWallet
                  usdcBalance={usdcBalance}
                  usdtBalance={usdtBalance}
                  setUsdcBalance={setUsdcBalance}
                  setUsdtBalance={setUsdtBalance}
                />
              )}
            </Box>
            <Divider />
            <Box flexGrow={1} padding={2}>
              <Box height={"100%"}>
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  flexDirection={"row"}
                >
                  <Stack>
                    <Typography sx={{ fontSize: "0.875rem" }}>
                      Total account balance
                    </Typography>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      gap={1}
                      alignItems={"center"}
                    >
                      <Typography variant="h5">
                        {showTotalAccountBalance
                          ? accountsData?.total_accounts_balance ?? 0
                          : "*****"}{" "}
                        {accountsData?.currency ?? "USD"}
                      </Typography>
                      <IconButton
                        onClick={() =>
                          setShowTotalAccountBalance((prev) => !prev)
                        }
                      >
                        {!showTotalAccountBalance ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </Box>
                    {accountsData?.accounts?.length > 1 && (
                      <Typography sx={{ fontSize: "0.6875rem" }}>
                        among {accountsData?.accounts?.length} account(s)
                      </Typography>
                    )}
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    gap={2}
                  >
                    <Stack
                      justifyContent={"center"}
                      alignItems={"center"}
                      gap={1}
                    >
                      <IconButton
                        sx={{
                          border: "1px solid #462A9C",
                          borderRadius: "4px",
                          padding: "4px",
                          height: "25px",
                          width: "25px",
                          stroke: "#462A9C",
                        }}
                        disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                        onClick={() => setOpenAccountModal(true)}
                      >
                        <Plus />
                      </IconButton>
                      <Typography align="center" sx={{ fontSize: "0.625rem" }}>
                        Add
                        <br />
                        account
                      </Typography>
                    </Stack>
                    {/* <Stack justifyContent={"center"} alignItems={"center"} gap={1}>
                      <IconButton
                        sx={{
                          border: "1px solid #462A9C",
                          borderRadius: "4px",
                          padding: "4px",
                          height: "25px",
                          width: "25px",
                        }}
                        disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
                        onClick={() => setInternalTransferModalOpen(true)}
                      >
                        <SyncAltIcon sx={{ color: "#462A9C", fontSize:"12px" }} />
                      </IconButton>
                      <Typography align="center" sx={{ fontSize: "10px" }}>Internal<br/>Transfer</Typography>
                    </Stack> */}
                    {/* <Stack justifyContent={"center"} alignItems={"center"} gap={1}>
                      <IconButton
                        sx={{
                          border: "1px solid #462A9C",
                          borderRadius: "4px",
                          padding: "4px",
                          height: "25px",
                          width: "25px",
                          cursor: "not-allowed",
                        }}
                        disabled
                      >
                        <AddCardIcon sx={{ color: "#462A9C", fontSize:"12px" }} />
                      </IconButton>
                      <Typography align="center" sx={{ fontSize: "10px" }}>Top up<br/>account</Typography>
                    </Stack> */}
                  </Stack>
                </Box>
                <Box
                  display={"flex"}
                  flex={1}
                  mt={2}
                  justifyContent={"flex-start"}
                  alignItems={"center"}
                  gap={1}
                  sx={{ overflowX: "auto" }}
                >
                  {accountsData?.accounts?.slice(0, 3)?.map((item) => {
                    const imagePath =
                      flagImages[`${item.currency?.toLowerCase()}.png`] || "";
                    return (
                      <Paper
                        variant="outlined"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: 2,
                          padding: "8px 10px",
                          width: "100%",
                          maxWidth: { xs: "100%", sm: "50%", md: "33.33%" },
                        }}
                      >
                        <img
                          src={imagePath}
                          alt={item.currency}
                          style={{
                            width: "30px",
                            fontFamily: "PPMori-Regular",
                            backgroundColor: "#F8F8F7",
                            padding: 5,
                            borderRadius: "5px",
                          }}
                        />
                        <Stack>
                          <Stack
                            flexDirection={"row"}
                            justifyContent={"flex-start"}
                            alignItems={"center"}
                            gap={1}
                          >
                            <Typography sx={{ fontSize: "0.9375rem" }}>
                              {item.currency}
                            </Typography>
                            <Typography sx={{ fontSize: "0.9375rem" }}>
                              {item.balance}
                            </Typography>
                          </Stack>
                          <Typography
                            sx={{ fontSize: "0.6875rem", color: "#92918E" }}
                          >
                            {item.name}
                          </Typography>
                        </Stack>
                      </Paper>
                    );
                  })}
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    sx={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      profile?.kycInfo?.kycStatus === "COMPLETED"
                        ? navigate("/accounts")
                        : console.log("abc");
                    }}
                  >
                    {accountsData?.accounts &&
                    accountsData?.accounts?.length > 3 ? (
                      <Paper
                        variant="outlined"
                        sx={{
                          padding: "5px",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "0.8125rem", fontWeight: "semi-bold" }}
                        >
                          +{" "}
                          {accountsData?.accounts &&
                          accountsData?.accounts?.length > 3
                            ? accountsData.accounts.length - 3
                            : 0}
                        </Typography>
                      </Paper>
                    ) : null}
                    {accountsData?.accounts?.length > 1 && (
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          fontWeight: "semi-bold",
                          marginLeft: "5px",
                          textDecoration:
                            accountsData?.accounts &&
                            accountsData?.accounts?.length > 3
                              ? null
                              : "underline",
                        }}
                      >
                        See all accounts
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Paper>
          {loadingQuote ? (
            <CardLoader />
          ) : (
            <Paper
              variant="outlined"
              sx={{
                height: "350px",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                padding: "16px",
                gap: "16px",
              }}
            >
              <Typography variant="h6" sx={{ fontSize: "0.875rem" }}>
                Currency calculator
              </Typography>

              <Box container spacing={0} alignItems="center" gap={1}>
                <FormLabel
                  style={{
                    fontSize: "0.75rem",
                    fontFamily: "PPMori-Regular",
                  }}
                >
                  You send
                </FormLabel>
                <Box
                  display={"flex"}
                  gap={1}
                  container
                  spacing={0}
                  alignItems="center"
                >
                  <TextField
                    fullWidth
                    type="text"
                    value={sendAmount}
                    onChange={handleSendAmountChange}
                    variant="outlined"
                    placeholder="$0.00"
                    inputProps={{
                      style: {
                        height: "10px",
                        fontSize: "0.75rem",
                        fontFamily: "PPMori-Regular",
                        borderColor: amountValid ? "initial" : "red",
                      },
                    }}
                  />
                  <TextField
                    select
                    value={sendCurrency}
                    onChange={handleSendCurrencyChange}
                    variant="outlined"
                    sx={{ width: "150px" }}
                    SelectProps={{
                      style: {
                        height: "45px",
                        fontSize: "0.75rem",
                        fontFamily: "PPMori-Regular",
                      },
                    }}
                  >
                    {sourceRegularCurrencies.map((currency, index) => {
                      const countryCode = currency.isCrypto
                        ? currency.code.toLowerCase()
                        : currency.country.toLowerCase();

                      // Construct the image path
                      const imagePath = images[`${countryCode}.png`];

                      return (
                        <MenuItem key={currency.code} value={currency.code}>
                          <img
                            src={imagePath}
                            alt={currency.code}
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              fontFamily: "PPMori-Regular",
                            }}
                          />
                          {currency.code}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
                {showQuoteError !== "" && (
                  <FormHelperText error>{showQuoteError}</FormHelperText>
                )}
              </Box>

              <Box container spacing={0} alignItems="center">
                <FormLabel style={{ fontSize: "0.75rem" }}>
                  Recipient gets
                </FormLabel>
                <Box
                  display={"flex"}
                  gap={1}
                  container
                  spacing={0}
                  alignItems="center"
                >
                  <TextField
                    fullWidth
                    type="text"
                    value={receiveAmount}
                    onChange={handleReciveAmountChange}
                    variant="outlined"
                    disabled
                    placeholder="$0.00"
                    inputProps={{
                      style: {
                        height: "10px",
                        fontSize: "0.75rem",
                        fontFamily: "PPMori-Regular",
                        color: "black",
                        WebkitTextFillColor: "black",
                      },
                    }}
                  />

                  <TextField
                    select
                    value={receiveCurrency}
                    onChange={handleReceiveCurrencyChange}
                    variant="outlined"
                    sx={{ width: "150px" }}
                    SelectProps={{
                      style: {
                        height: "45px",
                        fontSize: "0.75rem",
                        fontFamily: "PPMori-Regular",
                      },
                    }}
                  >
                    {destinationRegularCurrencies.map((currency) => {
                      return (
                        <MenuItem key={currency.code} value={currency.code}>
                          <img
                            src={
                              images[`${currency.code.toLowerCase()}.png`] ||
                              images[`${currency.country.toLowerCase()}.png`]
                            }
                            alt={currency.code}
                            style={{
                              width: "15px",
                              marginRight: "10px",
                              fontFamily: "PPMori-Regular",
                            }}
                            onClick={handleSendAmountChange}
                          />
                          {currency.code}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </Box>
              </Box>

              <Box style={{ maxHeight: "100vh" }}>
                {chargesFee.map((charge, index) => (
                  <Grid container key={index}>
                    <Grid item xs={6}>
                      {index === 0 && (
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "0.875rem",
                            fontFamily: "PPMori-Regular",
                          }}
                        >
                          Exchange rate
                        </Typography>
                      )}
                      {index === chargesFee.length - 1 && (
                        <><Box display={'flex'} alignItems={'centre'}><Typography
                          variant="body1"
                          style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}
                        >
                          Total fee
                        </Typography></Box><span onClick={() => setshowCharges(true)} style={{cursor:'pointer',color: "#462A9C", fontSize: "12px"}}>Show more</span></>
                      )}
                    </Grid>
                    <Grid item xs={6} style={{ textAlign: "right" }}>
                      {index === 0 && (
                        <Typography
                          variant="body1"
                          style={{
                            fontSize: "0.875rem",
                            fontFamily: "PPMori-Regular",
                          }}
                        >
                          1 {sendCurrency} ={" "}
                          {`${exchangeRate} ${receiveCurrency}`}
                        </Typography>
                      )}
                      {index === chargesFee.length - 1 && (
                        <><Typography
                          variant="body1"
                          style={{
                            fontSize: "0.875rem",
                            fontFamily: "PPMori-Regular",
                          }}
                        >
                          {chargeTotal} {charge.chargeCurrency}
                        </Typography></>
                      )}
                    </Grid>
                  </Grid>
                ))}
              </Box>
            </Paper>
          )}
        </Box>
        <Grid
          item
          xs={12}
          md={6}
          sx={{ marginLeft: "20px", marginRight: "20px" }}
        >
          <Box
            py={1}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography variant="p">Recent transactions</Typography>
            <Button
              variant="text"
              style={{
                backgroundColor: "white",
                color: "#462A9C",
                fontSize: "0.75rem",
                fontFamily: "PPMori-Regular",
                textTransform: "none",
                fontWeight: "bold",
              }}
              disabled={profile?.kycInfo?.kycStatus !== "COMPLETED"}
              onClick={() => {
                navigate("/transactions");
              }}
            >
              See all
            </Button>
          </Box>
          <Paper variant="outlined">
            <DashboardTransactionTable
              rows={transactionsList}
              page={page}
              rowsPerPage={rowsPerPage}
              loading={loading}
              onNoTransactionYetClick={setOpenSendMoney}
              currencyList={sourceRegularCurrencies}
            />
          </Paper>
        </Grid>
      </Box>
      {openAccountModal && (
        <OpenAccountModal
          open={openAccountModal}
          onClose={() => setOpenAccountModal(false)}
          onAccountOpen={onAccountOpen}
        />
      )}
      {internalTransferModalOpen && (
        <InternalTransferModal
          open={internalTransferModalOpen}
          onClose={() => setInternalTransferModalOpen(false)}
        />
      )}
      {showCharges && (
        <ChargesModal chargesFee={chargesFee} chargeTotal={chargeTotal} showCharges={showCharges} onClose={() => setshowCharges(false)} sendAmount={sendAmount} sendCurrency={sendCurrency} receiveAmount={receiveAmount} receiveCurrency={receiveCurrency}/>
      )}
    </>
  );
};

export default Dashboard;
