import React from "react";
import { Box, Typography, CircularProgress } from "@mui/material";

const LoadingTxn = ({ message }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="flex-start"
      alignItems="center"
      textAlign="center"
      gap={1}
      height={"100%"}
    >
      <CircularProgress
        size={40}
        thickness={3}
        sx={{
          my: 7,
          color: "black",
          animationDuration: "5s"
        }}
      />
      {/* <Typography
        variant="body2"
        gutterBottom
        style={{ fontFamily: "PPMori-Regular", fontSize: "20px" }}
      >
        {title}
      </Typography> */}
      <Typography
        variant="body2"
        style={{ fontFamily: "PPMori-Regular", fontSize: "18px" }}
      >
        {message}
      </Typography>
      <Typography
        variant="body2"
        style={{ fontFamily: "PPMori-Regular", fontSize: "16px", marginTop: "8px" }}
      >
        All done. We are now waiting to receive your cryptocurrency.
      </Typography>
      <Typography>
      This typically takes only a few minutes.
      </Typography>
    </Box>
  );
};

export default LoadingTxn;
