import React, { useState, useEffect, useCallback } from "react";
import { Grid, Typography, Button, Box, Tab, Stack } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import "./Recipients.css";
import { ReactComponent as file } from "../../Images/file-download.svg";
import { ReactComponent as exportfile } from "../../Images/file-export.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { NavLink, useLocation } from "react-router-dom";
import { getAllRecipients } from "../../lib/service/RecipientsApi/RecipientsApi";
import jsPDF from "jspdf";
import Notification from "../../components/Notification/Notification";
import { saveAs } from "file-saver";
import excelFile from "../../Images/EndlBulk_Payments_Global_Template (1) - Copy.xlsx";
import PopoverList from "../../components/Popover/PopoverList";
import { ReactComponent as Plus } from "../../Images/plus1.svg";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import AddAndEditManualRecipientModal from "../../components/ModalComponent/AddAndEditManualRecipientModal";
import AddRecipientBulkUpload from "../../components/ModalComponent/AddRecipientBulkUpload";
import RecipientDeleteModal from "../../components/ModalComponent/RecipientDeleteModal";
import AllRecipientTab from "./AllRecipientTab";
import RecipientHistoryTab from "./RecipientHistoryTab";
import dollar from "../../Images/dollar.png";
import { getDateRangeString } from "../../utils/date.helpers";
import { getCurrency } from "../../lib/service/DashboardApi/dashboardApi";
import { formatDate } from "../../lib/service/AuthToken";
import { getCombinedAddress } from "../../utils/recipeint.helpers";
import Papa from 'papaparse';
import commonHelpers from "../../utils/common.helpers";
const HISTORY_DUMMY = [
  {
    date: "12.02.24",
    name: "John Doe",
    recipient: "Acme Corp",
    id: 123456,
    currency: dollar,
    status: "Active",
    history: [
      {
        date: "Full name",
        customerId: "IBAN/Account number",
        sender: "Sender name",
      },
      {
        date: "Acme Corp inc",
        customerId: "243525d..65D76",
        sender: "Jane john doe",
      },
      {
        date: "Recipient ID",
        customerId: "Account holder name",
        sender: "Sender ID",
      },
      {
        date: "12345678",
        customerId: " John Doe",
        sender: "12345679",
      },
      {
        date: "Email",
        customerId: "SWIFT/BIC code",
      },
      {
        date: "janedoe@gmail.com",
        customerId: "AAAA-BB-CC-123",
      },
      {
        date: "Address",
        customerId: "Bank Name",
      },
      {
        date: "448 Prudence Street,Westland,MI,4818",
        customerId: "Bank Name",
      },
    ],
  },
  {
    date: "12.02.24",
    name: "John Doe",
    recipient: "Acme Corp",
    id: 123456,
    currency: dollar,
    status: "Active",
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  },
  {
    date: "12.02.24",
    name: "John Doe",
    recipient: "Acme Corp",
    id: 123456,
    currency: dollar,
    status: "Active",
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  },
  {
    date: "12.02.24",
    name: "John Doe",
    recipient: "Acme Corp",
    id: 123456,
    currency: dollar,
    status: "Active",
    history: [
      {
        date: "2020-01-05",
        customerId: "11091700",
        amount: 3,
      },
      {
        date: "2020-01-02",
        customerId: "Anonymous",
        amount: 1,
      },
    ],
  },
];

const initialRecipientFilterData = {
  recipientType: ["ALL"],
  paymentType: ["BANK_ACCOUNT"],
  sortBy: "createdOn,desc",
  page: 0,
  size: 5,
  search: "",
}

const Recipients = () => {
  const location = useLocation();
  const [recipientsData, setRecipientsData] = useState([]);
  const [histories, setHistories] = useState([]);
  const [isFetchingHistory, setIsFetchingHistory] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [addRecipientModalOpen, setAddRecipientModalOpen] = useState(false);
  const [addRecipientsPopoverEle, setaAddRecipientsPopoverEle] = useState(null);
  const [addRecipientBulkModalOpen, setAddRecipientBulkModalOpen] =
    useState(false);
  const [openEditRecipientModal, setOpenEditRecipientModal] = useState(false);
  const [openDeleteRecipientModal, setOpenDeleteRecipientModal] =
    useState(false);
  const [selectedRecipient, setSelectedRecipient] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [rangeDate, setRangeDate] = useState(null);
  const [statusType, setStatusType] = useState("ALL");
  const [currentActiveTab, setCurrentActiveTab] = useState("ALL_RECIPIENT");
  const [sourceRegularCurrencies, setSourceRegularCurrencies] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [recipientFilterData, setRecipientFilterData] = useState({ ...initialRecipientFilterData });

  useEffect(() => {
    if (location && location.state && location.state.openAddRecipientModal) {
      setAddRecipientModalOpen(true);
    }
    fetchCurrencyList();
  }, [location]);

  useEffect(() => {
    fetchAllRecipientsData();
  }, [recipientFilterData]);

  const fetchAllRecipientsData = async () => {
    setIsLoading(true);
    try {
      let payload = {
        page: recipientFilterData.page,
        size: recipientFilterData.size,
        sortBy: recipientFilterData.sortBy,
        recipientType: recipientFilterData.recipientType,
        receiveMode: recipientFilterData.paymentType,
        // Commented for future refrence
        // search: recipientFilterData.search,
      }
      if (payload.recipientType[0] === "ALL") {
        payload.recipientType = ["BUSINESS", "INDIVIDUAL"];
      }
      const res = await getAllRecipients(payload);
      if (res && res.data && res.data.recipients) {
        setRecipientsData(res.data.recipients);
        setTotalItems(res.data.totalCount);
      } else {
        setRecipientsData([]);
        setTotalItems(0);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleNotificationClose = () => {
    setNotificationOpen(false);
  };

  const handleDownload = () => {
    saveAs(excelFile, "Recipients.xlsx");
  };

  const handleExportPDF = () => {
    const doc = new jsPDF('landscape');

    const tableBody = [];

    recipientsData.forEach((recipient) => {
      const {
        id,
        firstName,
        lastName,
        type,
        bankName,
        bankAccountNumber,
        currency,
        iban,
        ifsc,
        swiftBic,
        createdOn,
        receiveMode,
      } = recipient;

      tableBody.push([
        new Date(createdOn).toLocaleString(),
        id,
        commonHelpers.capitalizeFirstLetter(`${firstName} ${lastName}`),
        bankName,
        bankAccountNumber,
        receiveMode === "BANK_ACCOUNT" ? "Bank account" : "Crypto account",
        type,
        currency,
        ifsc,
        swiftBic,
        iban,
      ]);
    });


    const tableHeaders = [
      "Date", "ID", "Recipient Name", "Bank Name", "Bank Account No.", "Account type",
      "Recipient Type", "Currency", "IFSC", "SWIFT/BIC", "IBAN"];

    doc.autoTable({
      head: [tableHeaders],
      body: tableBody,
      startY: 20,
      theme: 'grid',
      styles: {
        fontSize: 8,
        cellPadding: 2,
      },
      headStyles: {
        fillColor: [100, 100, 100],
        textColor: [255, 255, 255],
        halign: 'center',
        valign: 'middle',
        fontSize: 9,
      },
      margin: { top: 30 }
    });

    const fileName = "Recipients.pdf";
    doc.save(fileName);
    setNotificationOpen(true);
    setNotificationMessage(`File "${fileName}" created successfully`);
  };

  const handleExportCSV = () => {
    const csvData = recipientsData.map(recipient => {

      const {
        id,
        firstName,
        lastName,
        alias,
        type,
        bankName,
        bankAccountNumber,
        currency,
        iban,
        ifsc,
        swiftBic,
        createdOn,
        receiveMode,
        email,
        address,
        bankRoutingNumber,
        dateOfBirth,
        relationshipToCustomer,
      } = recipient;

      return {
        'Date': formatDate(createdOn) || '',
        'ID': id,
        'Recipient Name': commonHelpers.capitalizeFirstLetter(`${firstName} ${lastName}`),
        'Alias/Nickname': alias || '',
        'Email': email || '',
        'Date of birth': dateOfBirth || '',
        'Relationship to recipient': relationshipToCustomer || '',
        'Bank Name': bankName || '',
        'Address': getCombinedAddress(address) || '',
        'Bank Account No.': bankAccountNumber || '',
        'Account type': receiveMode === "BANK_ACCOUNT" ? "Bank account" : "Crypto account" || '',
        'Recipient Type': type || '',
        'Currency': currency || '',
        'IFSC': ifsc || '',
        'SWIFT/BIC': swiftBic || '',
        'IBAN': iban || '',
        'Routing number': bankRoutingNumber || '',
      };
    });

    const csvString = Papa.unparse(csvData);
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", 'Recipients.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Exporting CSV is not supported in this browser.");
    }

    setNotificationOpen(true);
    setNotificationMessage(`File "Recipients.csv" created successfully`);
  };

  // Delete recipient
  const handleDelete = (id) => {
    setRecipientsData((prevData) =>
      prevData.filter((recipient) => recipient.id !== id)
    );
    handleDeleteRecipientModalClose();
  };

  const handleDeleteRecipientModalOpen = (recipient) => {
    setSelectedRecipient(recipient);
    setOpenDeleteRecipientModal(true);
  };

  const handleDeleteRecipientModalClose = () => {
    setSelectedRecipient(null);
    setOpenDeleteRecipientModal(false);
  };

  // Update Recipient
  const handleUpdate = (updatedRecipient) => {
    fetchAllRecipientsData();
    setRecipientsData((prevData) =>
      prevData.map((recipient) =>
        recipient.id === updatedRecipient.id ? updatedRecipient : recipient
      )
    );
    handleUpdateRecipientClose();
  };

  const handleUpdateRecipientModalOpen = (recipient) => {
    setSelectedRecipient(recipient);
    setOpenEditRecipientModal(true);
  };

  const handleUpdateRecipientClose = () => {
    setSelectedRecipient(null);
    setOpenEditRecipientModal(false);
  };

  const AddRecipientsPopoverOptions = [
    {
      label: "Add recipient manually",
      onClick: () => {
        setaAddRecipientsPopoverEle(null);
        setAddRecipientModalOpen(true);
      },
      icon: <PersonAddAltOutlinedIcon />,
    },
    // {
    //   label: "Import recipients from CSV file",
    //   onClick: () => {
    //     setaAddRecipientsPopoverEle(true);
    //     setAddRecipientBulkModalOpen(true);
    //   },
    //   icon: <NoteAddOutlinedIcon />,
    // },
  ];

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { source, destination } = response.data;
      console.log(source, destination, 'ccewewf')
      setSourceRegularCurrencies(source.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRecipientFilterData(prevState => ({
      ...prevState,
      page: 0,
      size: newRowsPerPage
    }))
  };

  const handleClearAll = () => {
    setRecipientFilterData({ ...initialRecipientFilterData });
  }

  const handleFilterChange = ({ name, value }) => {
    setRecipientFilterData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleClearAllButton = useCallback(() => {
    return JSON.stringify(initialRecipientFilterData) !== JSON.stringify(recipientFilterData);
  }, [initialRecipientFilterData, recipientFilterData]);

  return (
    <>
      {/* {addRecipientBulkModalOpen && (
        <AddRecipientBulkUpload
          open={addRecipientBulkModalOpen}
          onClose={() => setAddRecipientBulkModalOpen(false)}
        />
      )} */}
      {openEditRecipientModal && selectedRecipient && (
        <AddAndEditManualRecipientModal
          open={openEditRecipientModal}
          onClose={handleUpdateRecipientClose}
          mode="edit"
          editRecipientData={selectedRecipient}
          onUpdateRecipient={handleUpdate}
        />
      )}
      {addRecipientModalOpen && (
        <AddAndEditManualRecipientModal
          open={addRecipientModalOpen}
          onClose={() => setAddRecipientModalOpen(false)}
          addNewRecipient={(data) => {
            fetchAllRecipientsData();
          }}
        />
      )}
      {openDeleteRecipientModal && selectedRecipient && (
        <RecipientDeleteModal
          open={openDeleteRecipientModal}
          onClose={handleDeleteRecipientModalClose}
          deleteRecipientData={selectedRecipient}
          onDelete={handleDelete}
        />
      )}
      <TabContext value={currentActiveTab}>
        <Box display={"flex"} flexDirection={"column"} height={"100svh"}>
          <Box
            container
            direction="column"
            alignItems="flex-start"
            spacing={3}
            style={{
              backgroundColor: "#F6F5F9",
            }}
          >
            {/* <div className="bggray"> */}

            <Box
              padding={3}
              style={{
                width: "100%",
                backgroundColor: "#F6F5F9",
              }}
            >
              {/* <Grid
                container
                direction={{ xs: "column", sm: "row"}}
                spacing={2}
                className="flex-class"
                justifyContent={{xs: "flex-start", sm: "space-between"}}
              >
                <Grid item xs={3}>
                  <Typography
                    variant="h3"
                    fontSize="26px"
                    fontFamily='PPMori-Regular'
                  >
                    Recipients
                  </Typography>
                </Grid>
                <Grid item xs={9}>
                  <Grid
                    container
                    direction="row"
                    spacing={2}
                    justifyContent={{ xs: "flex-start", sm: "flex-end"}}
                  >
                    {recipientsData.length ? (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              borderColor: "#462A9C",
                              color: "#462A9C",
                              textTransform: "none",
                              fontFamily: "PPMori-Regular",
                            }}
                            fullWidth
                            startIcon={<SvgIcon component={exportfile} />}
                            onClick={handleExportPDF}
                          >
                            Export PDF
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{
                              borderColor: "#462A9C",
                              color: "#462A9C",
                              textTransform: "none",
                              fontFamily: "PPMori-Regular",
                            }}
                            fullWidth
                            startIcon={<SvgIcon component={exportfile} />}
                            onClick={handleExportCSV}
                          >
                            Export CSV
                          </Button>
                        </Grid>
                      </>
                    ): null}
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#462A9C",
                          color: "#ffffff",
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                        }}
                        fullWidt
                        startIcon={<SvgIcon component={Plus} />}
                        onClick={(e) => setaAddRecipientsPopoverEle(e.target)}
                      >
                        Add recipient
                      </Button>
                      <PopoverList
                        open={Boolean(addRecipientsPopoverEle)}
                        anchorEl={addRecipientsPopoverEle}
                        handleClose={() => setaAddRecipientsPopoverEle(null)}
                        options={AddRecipientsPopoverOptions}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid> */}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                className="flex-class"
                justifyContent="space-between"
                alignItems={{sm: "center"}}
                sx={{ width: "100%" }}
              >
                <Typography
                  variant="h3"
                  fontSize="26px"
                  fontFamily="PPMori-Regular"
                  sx={{ textAlign: { xs: "left", sm: "left" } }}
                >
                  Recipients
                </Typography>

                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={2}
                  justifyContent="flex-end"
                  sx={{ width: { xs: "100%", sm: "auto" }, flexWrap: "wrap" }}
                >
                  {recipientsData.length ? (
                    <Stack height={{ xs: "50%" }} direction={{ xs: "row" }} gap={{ xs: 2, sm: 2 }} justifyContent={{ xs: "space-between" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          borderColor: "#462A9C",
                          color: "#462A9C",
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                          width: { xs: "100%", sm: "auto" },
                          height: { xs: "50%" }
                        }}
                        startIcon={<SvgIcon component={exportfile} />}
                        onClick={handleExportPDF}
                      >
                        Export PDF
                      </Button>

                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                          borderColor: "#462A9C",
                          color: "#462A9C",
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                          width: { xs: "100%", sm: "auto" },
                          height: { xs: "50%" }
                        }}
                        startIcon={<SvgIcon component={exportfile} />}
                        onClick={handleExportCSV}
                      >
                        Export CSV
                      </Button>
                    </Stack>
                  ) : null}

                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      backgroundColor: "#462A9C",
                      color: "#ffffff",
                      textTransform: "none",
                      fontFamily: "PPMori-Regular",
                      width: { xs: "100%", sm: "auto" },
                    }}
                    startIcon={<SvgIcon component={Plus} />}
                    onClick={(e) => setaAddRecipientsPopoverEle(e.target)}
                  >
                    Add recipient
                  </Button>

                  <PopoverList
                    open={Boolean(addRecipientsPopoverEle)}
                    anchorEl={addRecipientsPopoverEle}
                    handleClose={() => setaAddRecipientsPopoverEle(null)}
                    options={AddRecipientsPopoverOptions}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  />
                </Stack>
              </Stack>

            </Box>
            <TabList
              sx={{
                px: 2,
              }}
              className="links"
              onChange={(e, newActiveTab) => setCurrentActiveTab(newActiveTab)}
              TabIndicatorProps={{
                sx: {
                  backgroundColor: "#462A9C",
                },
              }}
            >
              {[
                {
                  label: "All recipient",
                  value: "ALL_RECIPIENT",
                },
                // {
                //   label: "History",
                //   value: "HISTORY",
                // },
              ].map((tab) => (
                <Tab
                  label={
                    <Typography
                      sx={{
                        color: "black",
                        fontWeight: 600,
                        textTransform: "none",
                      }}
                    >
                      {tab.label}
                    </Typography>
                  }
                  value={tab.value}
                  key={tab.value}
                />
              ))}
            </TabList>
          </Box>
          <TabPanel
            value="ALL_RECIPIENT"
            sx={{
              padding: 2,
              display: "flex",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <AllRecipientTab
              paymentType={recipientFilterData.paymentType}
              setPaymentType={(value) => handleFilterChange({ name: 'paymentType', value })}
              recipientType={recipientFilterData.recipientType}
              setRecipientType={(value) => {
                setRecipientFilterData(prevState => ({
                  ...prevState,
                  page: 0,
                }))
                handleFilterChange({ name: 'recipientType', value })
              }
              }
              recipientsData={recipientsData}
              handleUpdateRecipientModal={handleUpdateRecipientModalOpen}
              handleDeleteRecipientModal={handleDeleteRecipientModalOpen}
              isLoading={isLoading}
              rangeDate={rangeDate}
              setRangeDate={setRangeDate}
              currencyListData={sourceRegularCurrencies}
              handleChangePage={(event, value) => handleFilterChange({ name: 'page', value })}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              page={recipientFilterData.page}
              rowsPerPage={recipientFilterData.size}
              totalItems={totalItems}
              onClearFilter={handleClearAll}
              onSearchChange={(e) => handleFilterChange({ name: 'search', value: e.target.value?.toLowerCase() })}
              searchValue={recipientFilterData.search}
              showClearAllButton={handleClearAllButton}
            />
          </TabPanel>
          {/* <TabPanel value="HISTORY" sx={{ padding: 0 }}>
              <RecipientHistoryTab
                isLoading={isFetchingHistory}
                recipientHistories={histories}
                statusType={statusType}
                setStatusType={setStatusType}
              />
            </TabPanel> */}

          <Notification
            open={notificationOpen}
            onClose={handleNotificationClose}
            message={notificationMessage}
          />
        </Box>
      </TabContext>
    </>
  );
};

export default Recipients;
