import React from "react";
import {
    Box,
    FormControl,
    Select,
    Typography,
    MenuItem,
    TextField,
  } from "@mui/material";
export const USBankDetails = ({ formData, handleChange, formErrors, isEdit }) => {
  return (
    <>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account number
        </Typography>
        <TextField
          placeholder="Enter account number"
          onChange={(e) => handleChange("bankAccountNumber", e.target.value)}
          value={formData.bankAccountNumber}
          name="bankAccountNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankAccountNumber}
          disabled={isEdit}
        />
        {formErrors.bankAccountNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankAccountNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Routing number
        </Typography>
        <TextField
          placeholder="Enter routing number"
          onChange={(e) => handleChange("bankRoutingNumber", e.target.value)}
          value={formData.bankRoutingNumber}
          name="bankRoutingNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankRoutingNumber}
          disabled={isEdit}
        />
        {formErrors.bankRoutingNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankRoutingNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Swift BIC code
        </Typography>
        <TextField
          placeholder="Enter Swift BIC code"
          onChange={(e) => handleChange("swiftBic", e.target.value)}
          value={formData.swiftBic}
          name="swiftBic"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.swiftBic}
          disabled={isEdit}
        />
        {formErrors.swiftBic && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.swiftBic}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account type
        </Typography>
        <FormControl fullWidth error={formErrors.accountType}>
          <Select
            id="country-select"
            value={formData.accountType ? formData.accountType : "select"}
            onChange={(e) => handleChange("accountType", e.target.value)}
            name="accountType"
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 350,
                },
              },
            }}
            inputProps={{
              sx: {
                p: "11.5px 14px",
              },
            }}
            disabled={isEdit}
          >
            <MenuItem disabled value="select">
              Select
            </MenuItem>
            {[
              { value: "CHECKING", label: "Checking" },
              { value: "SAVING", label: "Saving" },
            ].map((accType) => (
              <MenuItem
                key={accType.value}
                value={accType.value}
                style={{
                  marginBottom: ".5rem",
                  marginTop: ".3rem",
                  minWidth: 250,
                }}
              >
                {accType.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {formErrors.accountType && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.accountType}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Bank name
        </Typography>
        <TextField
          placeholder="Enter bank name"
          onChange={(e) => handleChange("bankName", e.target.value)}
          value={formData.bankName}
          name="bankName"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankName}
          disabled={isEdit}
        />
        {formErrors.bankName && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankName}
          </Typography>
        )}
      </Box>
    </>
  );
};
