import React from "react";
import {
    Box,
    Typography,
    TextField,
  } from "@mui/material";
export const IndianBankDetails = ({ formData, handleChange, formErrors , isEdit }) => {
  return (
    <>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account number
        </Typography>
        <TextField
          placeholder="Enter account number"
          onChange={(e) => handleChange("bankAccountNumber", e.target.value)}
          value={formData.bankAccountNumber}
          name="bankAccountNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankAccountNumber}
          disabled={isEdit}
        />
        {formErrors.bankAccountNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankAccountNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          IFSC code
        </Typography>
        <TextField
          placeholder="Enter IFSC code"
          onChange={(e) => handleChange("ifsc", e.target.value)}
          value={formData.ifsc}
          name="ifsc"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.ifsc}
          disabled={isEdit}
        />
        {formErrors.ifsc && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.ifsc}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Swift BIC code
        </Typography>
        <TextField
          placeholder="Enter Swift BIC code"
          onChange={(e) => handleChange("swiftBic", e.target.value)}
          value={formData.swiftBic}
          name="swiftBic"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.swiftBic}
          disabled={isEdit}
        />
        {formErrors.swiftBic && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.swiftBic}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Bank name
        </Typography>
        <TextField
          placeholder="Enter bank name"
          onChange={(e) => handleChange("bankName", e.target.value)}
          value={formData.bankName}
          name="bankName"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankName}
          disabled={isEdit}
        />
        {formErrors.bankName && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankName}
          </Typography>
        )}
      </Box>
    </>
  );
};
