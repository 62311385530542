import React, { useState, useCallback, useEffect } from 'react';
import TranscationHistory from '../../pages/TranscationHistory/TranscationHistory';
import { getAllTranscation, getTranscationById } from '../../lib/service/TranscationApi/transcationApi';
import { transaction } from '../../components/MockApi/transcationapi';
import dayjs from 'dayjs';

const initialTransactionFilterData = {
  page: 0,
  size: 5,
  dateRange: "ALL_TIME",
  status: "",
  sortBy: "",
  endlTransactionMode: ["CRYPTO_TO_FIAT"]
}

const TranscationHistoryContainer = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalCount, setTotalCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [days, setDays] = useState(10);
  const [transactionsType, setTransactionsType] = useState('All');
  const [dateCreated, setDatecreated] = useState("createdOn,desc");
  const [loading, setLoading] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);
  const [filteredTransactions, setFilteredTransactions] = useState([]);
  const [status, setStatus] = useState('All');
  const [transactionFilterData, setTransactionFilterData] = useState({...initialTransactionFilterData});
  const [resetFilters, setResetFilters] = useState(false);

  // const getAllTranscationList = useCallback(async (paymentTypes = undefined, paymentMethods = undefined, recipientTypes = undefined, sourceCurrencys = undefined) => {
  //   try {
  //     const data = {
  //       page: page,
  //       size: rowsPerPage,
  //       transactionType: transactionsType,
  //       status: status,
  //       sort: dateCreated,
  //     };
  //     if (paymentTypes) {
  //       data.paymentType = paymentTypes.join(',');
  //     }
  //     if (paymentMethods) {
  //       data.paymentMethod = paymentMethods.join(',');
  //     }
  //     if (recipientTypes) {
  //       data.type = recipientTypes.join(',');
  //     }
  //     if (sourceCurrencys) {
  //       data.sourceCurrency = sourceCurrencys.join(',');
  //     }
  //     // const response = await getAllTranscation(data);
  //     // if (response.data && response.data['txns'].length) {
  //     //   setTransactionsList(response.data['txns']);
  //     //   setFilteredTransactions(response.data['txns']);
  //     //   setTotalCount(response.data.totalCount);
  //     // } else {
  //     //   setTransactionsList([]);
  //     //   setFilteredTransactions([]);
  //     // }
  //   } catch (error) {
  //     console.log("Error fetching transactions:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }, [page, rowsPerPage, transactionsType, status, dateCreated]);

  useEffect(() => {
    fetchAllTransaction();
  }, [transactionFilterData]);

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);
    if (value) {
      const filtered = transactionsList.filter(
        (transaction) =>
          transaction?.recipientData?.firstName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          transaction?.recipientData?.lastName
            ?.toLowerCase()
            .includes(value.toLowerCase()) ||
          transaction?.txnId.toLowerCase().includes(value.toLowerCase()) || 
          transaction?.recipientData?.firstName
          ?.toLowerCase()
          .includes(value?.split(" ")?.[0].toLowerCase()) &&
        transaction?.recipientData?.lastName
          ?.toLowerCase()
          .includes(value?.split(" ")?.[1].toLowerCase())
      );
      setFilteredTransactions(filtered);
    } else {
      setFilteredTransactions(transactionsList);
    }
  };

  const handleDays = (newValue) => {
    setPage(0);
    setDays(newValue || 10);
  };

  const handleTranscationChange = (event) => {
    const selectedValue = event.target.value;
    setTransactionsType(selectedValue);
  };

  const handleChangeDatecreated = (event) => {
    const selectedValue = event.target.value;
    setDatecreated(selectedValue);
    setTransactionFilterData(prevState => ({
      ...prevState,
      sortBy: selectedValue,
      page:0
    }));
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setTransactionFilterData(prevState => ({
      ...prevState,
      page: newPage,
    }));
  };

  const handleChangeRowsPerPage = async (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    setTransactionFilterData(prevState => ({
      ...prevState,
      size: newRowsPerPage,
      page: 0,
    }));
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
    setPage(0);
    setTransactionFilterData(prevState => ({
      ...prevState,
      status: event.target.value,
    }))
  };

  const handleClick = (event) => {
    setAnchorEl(event ? event.currentTarget : null);
    // Note for future refrence
    // if(!event){
    //   if(transactionFilterData.depositType){
    //     delete transactionFilterData.depositType
    //   }
    //   if(transactionFilterData.recipientType){
    //     delete transactionFilterData.recipientType
    //   }
    //   if(transactionFilterData.sourceCurrency){
    //     delete transactionFilterData.sourceCurrency
    //   }
    //   transactionFilterData.endlTransactionMode = ["CRYPTO_TO_FIAT"]
    //   setTransactionFilterData({...transactionFilterData});
    // }
  };

  const handleApplyFilters = (paymentType, paymentMethod, recipientType, sourceCurrency) => {
    const paymentTypes = [];
    const paymentMethods = [];
    const recipientTypes = [];
    const sourceCurrencys = [];
    if (paymentType.fiatToFiat) paymentTypes.push('FIAT_TO_FIAT');
    if (paymentType.fiatToCrypto) paymentTypes.push('FIAT_TO_CRYPTO');
    if (paymentType.cryptoToFiat) paymentTypes.push('CRYPTO_TO_FIAT');
    if (paymentMethod.connectToWallet) paymentMethods.push('CRYPTO_WALLET');
    if (paymentMethod.manualCryptopayment) paymentMethods.push('CRYPTO_MANUAL_WALLET');
    // Note:- Commented for now but will be available in upcomming release
    // if (paymentMethod.creditDebitCard) paymentMethods.push('creditDebitCard');
    // if (paymentMethod.aeonAccount) paymentMethods.push('aeonAccount');
    if (recipientType.individual) recipientTypes.push('INDIVIDUAL');
    if (recipientType.business) recipientTypes.push('BUSINESS');
    if (Object.keys(sourceCurrency).length) {
      Object.keys(sourceCurrency).forEach(type => {
        if(sourceCurrency[type]){
          sourceCurrencys.push(type);
        }
      });
    }
    setPage(0);
    setTransactionFilterData(prevState => ({
      ...prevState,
      endlTransactionMode: paymentTypes,
      depositType: paymentMethods,
      recipientType: recipientTypes,
      sourceCurrency: sourceCurrencys,
    }));
    setAnchorEl(null);
  };

  const handleDateFilterChange = (value) => {
    if(typeof(value) !== "string"){
      setPage(0);
      const startDate = dayjs(value[0]).format('DD-MM-YYYY');
      const endDate = dayjs(value[1]).format('DD-MM-YYYY');
      setTransactionFilterData(prevState => ({
        ...prevState,
        dateRange: "CUSTOM",
        startDate,
        endDate,
      }))
    } else {
      setTransactionFilterData(prevState => ({
        ...prevState,
        dateRange: value,
        startDate: null,
        endDate: null,
      }))
    }
  }

  const handleClearAllFilters = () => {
    setPage(0);
    setTransactionFilterData({...initialTransactionFilterData})
    setResetFilters(true);
    setTimeout(() => {
      setResetFilters(false);
    }, 1000)
  }

  const fetchAllTransaction = async() => {
    try {
      setLoading(true);
      const payload = Object.entries(transactionFilterData).reduce((acc, [key, value]) => {
        if (value !== "" && value !== null && value !== undefined && (!Array.isArray(value) || value.length > 0)) {
          acc[key] = value;
        }
        return acc;
      }, {});
      if(payload.startDate && payload.endDate && dayjs(payload.startDate).isSame(payload.endDate)){
        delete payload.endDate
        payload.dateRange = "SINGLE"
      }
      if(!payload.sortBy) {
        payload.sortBy = "createdOn,desc"
      }
      const res = await getAllTranscation(payload)
      if(res && res.data && res.data.txns){
        setTransactionsList(res.data.txns);
        setFilteredTransactions(res.data.txns)
        setTotalCount(res.data?.totalCount);
      } else {
        setTransactionsList([]);
        setFilteredTransactions([]);
      }
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false);
    }
  };

  const handleClearAllButton = useCallback(() => {
    return JSON.stringify(initialTransactionFilterData) !== JSON.stringify(transactionFilterData);
  }, [initialTransactionFilterData, transactionFilterData]);

  return (
    <TranscationHistory
      searchTerm={searchTerm}
      days={days}
      handleSearch={handleSearch}
      handleDays={handleDays}
      handleTranscationChange={handleTranscationChange}
      transactionsType={transactionsType}
      handleChangeDatecreated={handleChangeDatecreated}
      dateCreated={transactionFilterData.sortBy}
      rows={filteredTransactions}
      loading={loading}
      page={transactionFilterData.page}
      rowsPerPage={transactionFilterData.size}
      totalCount={totalCount}
      onChangePage={handleChangePage}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      handleChangeStatus={handleChangeStatus}
      status={transactionFilterData.status}
      handleApplyFilters={handleApplyFilters}
      anchorEl={anchorEl}
      handleClick={handleClick}
      handleDateFilterChange={handleDateFilterChange}
      clearAllFilters={handleClearAllFilters}
      resetFilters={resetFilters}
      showClearAllButton={handleClearAllButton}
      setPage={setPage}
    />
  );
};

export default TranscationHistoryContainer;
