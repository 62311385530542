import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Button,
  Grid,
  Input,
  IconButton,
  useMediaQuery,
  Stack
} from "@mui/material";
import { KeyboardArrowDown, Add, Delete } from "@mui/icons-material";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import AddIcon from "@mui/icons-material/Add";
import LoadingDetails from "../LoaderUI/TransferAmountLoader";
import { notify } from "../../components/Notification/Notification";
import { invoiceUpload } from "../../lib/service/DashboardApi/dashboardApi";
import ChargesModal from "../ModalComponent/CurrencyCalculator/ChargesModal";
const SendMoneyTransferAmountForm = ({
  sourceCurrency,
  destinationCurrencies,
  receiveCurrency,
  exchangeRate,
  receiveAmount,
  sendAmount,
  chargesFee,
  chargeTotal,
  handleReceiveCurrencyChange,
  handleSendAmountChange,
  isFetching,
  total,
  handleGoBack,
  handleNext,
  showReason,
  handleSelectChange,
  transactionPurpose,
  setinvoiceFileIdSave,
  showAmountError,
  setSelectedFile,
  selectedFile,
  isReasonMandatory,
}) => {
  const fileInputRef = useRef(null);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const isLargeScreen = useMediaQuery((theme) => theme.breakpoints.up("md"));
  const [showCharges, setshowCharges] = useState(false);

  const importAll = (r) => {
    let images = {};
    r.keys().forEach((item) => {
      images[item.replace("./", "")] = r(item);
    });
    return images;
  };

  const images = importAll(
    require.context("../../Images/flags", false, /\.(png)$/)
  );

  const handleSendAmountInputChange = (event) => {
    const amount = event.target.value;
    const numericRegex = /^[0-9]*$/;
    if (!numericRegex.test(amount)) {
      return;
    }
    handleSendAmountChange(amount);
  };


  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file.type === "application/pdf") {
      const response = await invoiceUpload(file);
      setSelectedFile(file);
      setinvoiceFileIdSave(response.data.invoiceFileId)
    } else {
      notify.error({
        message: "Only PDF file accepted",
        description: "",
      })
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileRemove = () => {
    setSelectedFile(null);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  return (
    <Box display={"flex"} flexDirection={"column"} gap={5} alignItems={isMobile ? "center" : ""}>
      {isMobile ? (
        <Stack gap={2} width={{xs:"100%", sm:"50%"}}>
          <Box display="flex" flexDirection="column">
            <Typography fontSize={"14px"}>You send</Typography>
            <OutlinedInput
              sx={{
                width: "100%",
                height: "45px",
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "12px 14px",
                  borderRight: "1px solid rgba(0,0,0,0.20)",
                },
              }}
              onChange={handleSendAmountInputChange}
              inputProps={{
                style: {
                  py: 1,
                },
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <img
                    src={images[`${sourceCurrency.toLowerCase()}.png`]}
                    alt={sourceCurrency.toLowerCase()}
                    style={{
                      width: "15px",
                      fontFamily: "PPMori-Regular",
                    }}
                  />
                  <Typography
                    variant="body6"
                    fontSize={15}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {sourceCurrency}
                  </Typography>
                </InputAdornment>
              }
              value={sendAmount}
            />
            {showAmountError && (
              <Typography
                variant="caption"
                color="error"
                display="block"
                mt={1}
                sx={{
                  whiteSpace: "normal",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {showAmountError}
              </Typography>
            )}
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography fontSize={"14px"}>Recipient gets</Typography>
            <OutlinedInput
              value={receiveAmount}
              sx={{
                width: "100%",
                height: "45px",
                "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                  padding: "12px 14px",
                  borderRight: "1px solid rgba(0,0,0,0.20)",
                },
              }}
              inputProps={{
                style: {
                  py: 1,
                },
              }}
              endAdornment={
                <InputAdornment
                  position="end"
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  <img
                    src={images[`${"india".toLowerCase()}.png`]}
                    alt={receiveCurrency.toLowerCase()}
                    style={{
                      width: "15px",
                      fontFamily: "PPMori-Regular",
                    }}
                  />
                  <Typography
                    variant="body6"
                    fontSize={15}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {receiveCurrency}
                  </Typography>
                </InputAdornment>
              }
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
          >
            <Typography fontSize={"14px"}>Transfer purpose</Typography>
            <Select
              labelId="reason-select-label"
              id="reason-select"
              value={showReason}
              onChange={handleSelectChange}
              sx={{
                width: "100%",
                height: "45px",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
                "& .MuiSelect-select": {
                  padding: "12px 14px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap", 
                },
              }}
              IconComponent={KeyboardArrowDown}
            >
              <MenuItem disabled value="">
                <em>Choose a reason for transfer</em>
              </MenuItem>
              {transactionPurpose.codes.map((item) => (
                      <MenuItem
                        value={item.code}
                        key={item.id}
                        name={item.description}
                        sx={{
                          width: "300px",
                          padding: "4px 8px",
                          maxHeight: "150px",
                          overflowY: "auto", 
                        }}
                      >
                        <Box display="flex" flexDirection="column" maxWidth="250px"> {/* Set max width for text */}
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px", // Main text font size
                              // whiteSpace: "normal", // Allow wrapping
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={item.category + ' - ' + item.code}
                          >
                            {item.category} - {item.code}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "10px",
                              color: "gray",
                              // whiteSpace: "normal", // Allow wrapping
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={item.description} // Tooltip for full description
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
            </Select>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            height="45px"
            alignItems= "center"
            pr={2}
          >
            <Typography fontSize={"14px"}>Fees</Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "PPMori-Semi-Bold",
                whiteSpace: "nowrap",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {chargeTotal} {"USD"}
            </Typography>
          </Box>
          <Button
            sx={{
              border: "1px dotted rgba(0,0,0,0.30)",
              color: "rgba(0,0,0,0.70)",
              textTransform: "none",
            }}
            startIcon={<FileUploadOutlinedIcon />}
            onClick={handleButtonClick}
          >
            Upload invoice
            {!selectedFile && isReasonMandatory && (
              <span style={{ color: "red" }}>*</span>
            )}
          </Button>

          <Input
            type="file"
            onChange={handleFileUpload}
            sx={{ display: "none" }}
            inputRef={fileInputRef}
          />
          {selectedFile && (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography
                variant="body2"
                fontSize="12px"
                sx={{ whiteSpace: "nowrap" }}
              >
                {selectedFile.name}
              </Typography>
              <IconButton onClick={handleFileRemove}>
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          )}
          {!selectedFile && isReasonMandatory && (
            <Box display="flex" alignItems="center" gap={1} color={"red"}>
              <span>Upload invoice is mandatory for this transfer purpose</span>
            </Box>
          )}
        </Stack>
      ) : (
        <TableContainer
          sx={{
            overflowX: "auto",
          }}
        >
          <Table
            sx={{
              border: "1px solid #D9D7DD",
              borderRadius: "10px",
              minWidth: "800px",
            }}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#FBFAFC" }}>
                <TableCell
                  sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}
                >
                  <Typography
                    variant="h6"
                    fontSize="12px"
                    fontFamily="PPMori-Semi-Bold"
                  >
                    You send
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}
                >
                  <Typography
                    variant="h6"
                    fontSize="12px"
                    fontFamily="PPMori-Semi-Bold"
                  >
                    Recipient gets
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", borderRight: "1px solid #ccc" }}
                >
                  <Typography
                    variant="h6"
                    fontFamily="PPMori-Semi-Bold"
                    fontSize="12px"
                  >
                    Transfer Purpose
                  </Typography>
                </TableCell>
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  <Typography
                    variant="h6"
                    fontFamily="PPMori-Semi-Bold"
                    fontSize="12px"
                  >
                    Fees
                  </Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
                >
                  <Box display="flex" flexDirection="column" minHeight="75px">
                    <OutlinedInput
                      sx={{
                        width: "100%",
                        height: "45px",
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "12px 14px",
                            borderRight: "1px solid rgba(0,0,0,0.20)",
                          },
                      }}
                      onChange={handleSendAmountInputChange}
                      inputProps={{
                        style: {
                          py: 1,
                        },
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <img
                            src={images[`${sourceCurrency.toLowerCase()}.png`]}
                            alt={sourceCurrency.toLowerCase()}
                            style={{
                              width: "15px",
                              fontFamily: "PPMori-Regular",
                            }}
                          />
                          <Typography
                            variant="body6"
                            fontSize={15}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {sourceCurrency}
                          </Typography>
                        </InputAdornment>
                      }
                      value={sendAmount}
                    />
                    {showAmountError && (
                      <Typography
                        variant="caption"
                        color="error"
                        display="block"
                        mt={1}
                        sx={{
                          whiteSpace: "normal",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {showAmountError}
                      </Typography>
                    )}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
                >
                  <Box display="flex" flexDirection="column" minHeight="75px">
                    <OutlinedInput
                      value={receiveAmount}
                      sx={{
                        width: "100%",
                        height: "45px",
                        "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "12px 14px",
                            borderRight: "1px solid rgba(0,0,0,0.20)",
                          },
                      }}
                      inputProps={{
                        style: {
                          py: 1,
                        },
                      }}
                      endAdornment={
                        <InputAdornment
                          position="end"
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <img
                            src={images[`${"india".toLowerCase()}.png`]}
                            alt={receiveCurrency.toLowerCase()}
                            style={{
                              width: "15px",
                              fontFamily: "PPMori-Regular",
                            }}
                          />
                          <Typography
                            variant="body6"
                            fontSize={15}
                            sx={{ whiteSpace: "nowrap" }}
                          >
                            {receiveCurrency}
                          </Typography>
                        </InputAdornment>
                      }
                    />
                  </Box>
                </TableCell>
                <TableCell
                  sx={{ whiteSpace: "nowrap", flex: 1, padding: "10px" }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    minHeight="75px"
                    minWidth="230px"
                    maxWidth="230px"
                  >
                    <Select
                      labelId="reason-select-label"
                      id="reason-select"
                      value={showReason}
                      onChange={handleSelectChange}
                      sx={{
                        width: "100%",
                        height: "45px",
                        fontSize: "12px",
                        fontFamily: "PPMori-Regular",
                        "& .MuiSelect-select": {
                          padding: "12px 14px",
                        },
                      }}
                      IconComponent={KeyboardArrowDown}
                    >
                      <MenuItem disabled value="">
                        <em>Choose a reason for transfer</em>
                      </MenuItem>
                      {transactionPurpose.codes.map((item) => (
                      <MenuItem
                        value={item.code}
                        key={item.id}
                        name={item.description}
                        sx={{
                          width: "300px", // Adjust width as needed
                          padding: "4px 8px",
                          maxHeight: "150px", // Set a max height for the dropdown
                          overflowY: "auto", // Allow scrolling for long content
                        }}
                      >
                        <Box display="flex" flexDirection="column" maxWidth="250px"> {/* Set max width for text */}
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "12px", // Main text font size
                              // whiteSpace: "normal", // Allow wrapping
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={item.category + ' - ' + item.code} // Tooltip for full text
                          >
                            {item.category} - {item.code}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              fontSize: "10px",
                              color: "gray",
                              // whiteSpace: "normal", // Allow wrapping
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                            title={item.description} // Tooltip for full description
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                    </Select>
                  </Box>
                </TableCell>

                <TableCell
                  sx={{
                    whiteSpace: "nowrap",
                    flex: 0,
                    width: "auto",
                    padding: "10px",
                  }}
                >
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    minHeight="75px"
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontFamily: "PPMori-Semi-Bold",
                        whiteSpace: "nowrap",
                        height: "45px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {chargeTotal} {"USD"}
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5}>
                  <Button
                    sx={{
                      border: "1px dotted rgba(0,0,0,0.30)",
                      width: "200px",
                      color: "rgba(0,0,0,0.70)",
                      textTransform: "none",
                    }}
                    startIcon={<FileUploadOutlinedIcon />}
                    onClick={handleButtonClick}
                  >
                    Upload file
                    {!selectedFile && isReasonMandatory && (
                      <Typography color="error" variant="caption">*</Typography>
                    )}
                  </Button>

                  <Input
                    type="file"
                    onChange={handleFileUpload}
                    sx={{ display: "none" }}
                    inputRef={fileInputRef}
                  />
                  {selectedFile && (
                    <Box display="flex" alignItems="center" gap={1}>
                      <Typography
                        variant="body2"
                        fontSize="12px"
                        sx={{ whiteSpace: "nowrap" }}
                      >
                        {selectedFile.name}
                      </Typography>
                      <IconButton onClick={handleFileRemove}>
                        <Delete fontSize="small" />
                      </IconButton>
                    </Box>
                  )}
                  {!selectedFile && isReasonMandatory && (
                    <Box
                      display="flex"
                      alignItems="center"
                      gap={1}
                    >
                      <Typography color="error" variant="caption">
                        Upload invoice is mandatory for this transfer purpose
                      </Typography>
                    </Box>
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <Box display={"flex"} width={{sm:"50%",md:"auto"}}>
        {isLargeScreen && <Box width={"100%"}></Box>}
        <Grid container spacing={2}>
          {isFetching ? (
            <Grid item xs={12}>
              <LoadingDetails />
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                {chargesFee.length && sendAmount?.length > 2
                  ? chargesFee.map((charge, index) => (
                      <>
                        {index === 0 && (
                          <Typography
                            variant="body1"
                            style={{
                              fontSize: "14px",
                              fontFamily: "PPMori-Regular",
                            }}
                          >
                            Exchange rate
                          </Typography>
                        )}
                        {index === chargesFee.length - 1 && (
                          <><Typography
                          variant="body1"
                          fontSize="14px"
                          lineHeight={2}
                          fontFamily="PPMori-Regular"
                        >
                          Total fee
                        </Typography><span onClick={() => setshowCharges(true)} style={{ cursor: 'pointer', color: "#462A9C", fontSize: "12px"}}>Show more</span></>
                        )}
                      </>
                    ))
                  : null}
              </Grid>
              <Grid item xs={6} textAlign="right">
                {chargesFee.length && sendAmount?.length > 2
                  ? chargesFee.map((charge, index) => (
                      <>
                        {index === 0 && (
                          <Typography
                            variant="body2"
                            fontSize="12px"
                            fontFamily="PPMori-Regular"
                            marginRight="20px"
                            lineHeight={2}
                          >
                            1 USD = {`${exchangeRate} ${receiveCurrency}`}
                          </Typography>
                        )}
                        {index === chargesFee.length - 1 && (
                          <Typography
                            variant="body2"
                            fontSize="12px"
                            fontFamily="PPMori-Regular"
                            marginRight="20px"
                            lineHeight={2}
                          >
                            {chargeTotal} USD
                          </Typography>
                        )}
                      </>
                    ))
                  : null}
              </Grid>
            </>
          )}
          <Box display={"flex"} gap={2} width={"100%"} mt={{xs:1 , md:2}}>
            <Button
              variant="outlined"
              color="primary"
              sx={{
                textTransform: "none",
                borderColor: "#462A9C",
                color: "#462A9C",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
                width: "100%",
                py: 1,
              }}
              fullWidth
              onClick={handleGoBack}
            >
              Go back
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{
                textTransform: "none",
                backgroundColor: "#462A9C",
                color: "#ffffff",
                fontSize: "12px",
                fontFamily: "PPMori-Regular",
                width: "100%",
                py: 1,
              }}
              onClick={handleNext}
              disabled={
                showAmountError ||
                !sendAmount ||
                isFetching ||
                showReason === "" ||
                (!selectedFile && isReasonMandatory)
              }
            >
              Next
            </Button>
          </Box>
        </Grid>
      </Box>
      {showCharges && (
        <ChargesModal chargesFee={chargesFee} chargeTotal={chargeTotal} showCharges={showCharges} onClose={() => setshowCharges(false)} sendAmount={sendAmount} sendCurrency={sourceCurrency} receiveAmount={receiveAmount} receiveCurrency={receiveCurrency}/>
      )}
    </Box>
  );
};

export default SendMoneyTransferAmountForm;
