import React from "react";
import {
    Box,
    Typography,
    TextField,
    Tooltip,
    IconButton,
  } from "@mui/material";
  import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
export const GreatBritainBankDetails = ({
  formData,
  handleChange,
  formErrors,
  isEdit,
}) => {
  return (
    <>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Account number
        </Typography>
        <TextField
          placeholder="Enter account number"
          onChange={(e) => handleChange("bankAccountNumber", e.target.value)}
          value={formData.bankAccountNumber}
          name="bankAccountNumber"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.bankAccountNumber}
          disabled={isEdit}
        />
        {formErrors.bankAccountNumber && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.bankAccountNumber}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          IBAN code
          <Tooltip
            fontFamily="PPMori-Regular"
            title={
              "IBAN number is 22 character long code. For eg. DE89370400440532013000"
            }
            arrow
            placement="right"
          >
            <IconButton sx={{ marginLeft: "4px" }}>
              <InfoOutlinedIcon
                sx={{
                  width: "15px",
                  height: "15px",
                }}
              />
            </IconButton>
          </Tooltip>
        </Typography>
        <TextField
          placeholder="Enter IBAN code"
          onChange={(e) => handleChange("iban", e.target.value)}
          value={formData.iban}
          name="iban"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.iban}
          disabled={isEdit}
        />
        {formErrors.iban && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.iban}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Swift BIC code
        </Typography>
        <TextField
          placeholder="Enter Swift BIC code"
          onChange={(e) => handleChange("swiftBic", e.target.value)}
          value={formData.swiftBic}
          name="swiftBic"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.swiftBic}
          disabled={isEdit}
        />
        {formErrors.swiftBic && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.swiftBic}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
        <Typography
          variant="body1"
          fontSize={12}
          sx={{
            "&::after": {
              content: '"*"',
              color: "red",
            },
          }}
        >
          Sort code
        </Typography>
        <TextField
          placeholder="Enter sort code"
          onChange={(e) => handleChange("sortCode", e.target.value)}
          value={formData.sortCode}
          name="sortCode"
          inputProps={{
            sx: {
              p: "11.5px 14px",
            },
          }}
          error={!!formErrors.sortCode}
          disabled={isEdit}
        />
        {formErrors.sortCode && (
          <Typography component={"small"} fontSize={12} sx={{ color: "red" }}>
            {formErrors.sortCode}
          </Typography>
        )}
      </Box>
      <Box display="flex" flexDirection={"column"} gap={"2px"}>
            <Typography
              variant="body1"
              fontSize={12}
              sx={{
                "&::after": {
                  content: '"*"',
                  color: "red",
                },
              }}
            >
              Bank name
            </Typography>
            <TextField
              placeholder="Enter bank name"
              onChange={(e) => handleChange("bankName", e.target.value)}
              value={formData.bankName}
              name="bankName"
              inputProps={{
                sx: {
                  p: "11.5px 14px",
                },
              }}
              error={!!formErrors.bankName}
              disabled={isEdit}
            />
            {formErrors.bankName && (
              <Typography
                component={"small"}
                fontSize={12}
                sx={{ color: "red" }}
              >
                {formErrors.bankName}
              </Typography>
            )}
          </Box>
    </>
  );
};
