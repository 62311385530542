import React, { useMemo, useState } from "react";
import {
  Grid,
  TextField,
  InputAdornment,
  Box,
  FormControl,
  MenuItem,
  Button,
  Paper,
  Typography,
  Select,
  Stack
} from "@mui/material";
import "./Recipients.css";
import CollapsibleTable from "../../components/TableComponent/RecipientsTable";
import SearchIcon from "@mui/icons-material/Search";
import { PastDays } from "../../components/BasicDateRangePicker/BasicDateRangePicker";

const paymanetDataOptions = [
  {
    label: "To Bank Account",
    value: "BANK_ACCOUNT",
    disable: false,
  },
  {
    label: "To Crypto Wallet",
    value: "CRYPTO_WALLET",
    disable: true,
  }
]

const recipientDataOptions = [
  // {
  //   label: "All",
  //   value: "ALL",
  // },
  {
    label: "Business",
    value: "BUSINESS",
  },
  {
    label: "Individual",
    value: "INDIVIDUAL",
  }
]

const AllRecipientTab = ({
  paymentType,
  setPaymentType,
  recipientType,
  setRecipientType,
  recipientsData,
  handleUpdateRecipientModal,
  handleDeleteRecipientModal,
  isLoading,
  rangeDate,
  setRangeDate,
  currencyListData,
  handleChangePage,
  handleChangeRowsPerPage,
  page,
  rowsPerPage,
  totalItems,
  onClearFilter,
  onSearchChange,
  searchValue,
  showClearAllButton,
}) => {

  return (
    <>
      <Box>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={2}
          justifyContent={{ sm: "space-between" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          sx={{ my: 2, width: "100%" }}
        >
          <TextField
            placeholder="Search by keywords..."
            variant="outlined"
            size="small"
            onChange={(e) => onSearchChange(e)}
            value={searchValue || ""}
            fullWidth
            sx={{
              width: {
                xs: "100%",
                sm: "40%",
                lg: "30%",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ width: "20px", height: "20px" }} />
                </InputAdornment>
              ),
            }}
          />

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2} sx={{ width: { xs: "100%", sm: "auto" } }}>
            <FormControl size="small" fullWidth>
              <Select
                id="recipient-type-select-label"
                value={recipientType[0]}
                displayEmpty
                onChange={(e) => setRecipientType([e.target.value])}
                renderValue={(selected) => {
                  if (!selected || selected === "") {
                    return "Recipient type";
                  }
                  const selectedValueLabel = recipientDataOptions.find(fd => fd.value === selected)?.label;
                  return selectedValueLabel || "Recipient type";
                }}
              >
                {recipientDataOptions.map(item => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    style={{ fontSize: '14px', fontFamily: 'PPMori-Regular' }}
                    disabled={item.disable}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl size="small" fullWidth>
              <Select
                labelId="payment-type-select-label"
                id="payment-type-select"
                value={paymentType[0] || ""}
                onChange={(e) => setPaymentType([e.target.value])}
                renderValue={(selected) => {
                  if (!selected || selected === "") {
                    return "Payment type";
                  }
                  const selectedValueLabel = paymanetDataOptions.find(fd => fd.value === selected)?.label;
                  return selectedValueLabel || "Payment type";
                }}
              >
                {paymanetDataOptions.map(item => (
                  <MenuItem
                    key={item.value}
                    value={item.value}
                    disabled={item.disable}
                  >
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {showClearAllButton() && (
              <Box
                display="flex"
                justifyContent={{ xs: "center", sm: "flex-start" }}
                alignItems="center"
                width="100%"
              >
                <Typography
                  variant="outlined"
                  size="small"
                  onClick={onClearFilter}
                  sx={{
                    cursor: 'pointer',
                    color: '#462A9C',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    width: { xs: '100%', sm: 'auto' },
                    marginTop: { xs: 2, sm: 0 },
                  }}
                >
                  Clear All
                </Typography>
              </Box>
            )}

          </Stack>
        </Stack>
      </Box>
      <Box
        flexGrow={1}
        overflow={"hidden"}
        border={1}
        borderColor={"rgba(0,0,0,0.1)"}
        borderRadius={1}
      >
        <CollapsibleTable
          recipientsData={
            recipientsData.length
              ? recipientsData.filter(fd =>
                fd.firstName?.toLowerCase().includes(searchValue) ||
                fd.lastName?.toLowerCase().includes(searchValue) ||
                fd.id.toLowerCase().includes(searchValue) ||
                fd.firstName?.toLowerCase().includes(searchValue?.split(" ")?.[0]) &&
                fd.lastName?.toLowerCase().includes(searchValue?.split(" ")?.[1])
              )
              : recipientsData
          }
          onUpdateRecipientClick={handleUpdateRecipientModal}
          onDeleteRecipientClick={handleDeleteRecipientModal}
          isLoading={isLoading}
          currencyListData={currencyListData}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          totalItems={totalItems}
        />
      </Box>
    </>
  );
};

export default AllRecipientTab;
