import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { ReactComponent as GoogleIcon } from "../../Images/googleIcon.svg";
import { ReactComponent as LinkedInIcon } from "../../Images/linkedIcon.svg";
import { ReactComponent as FacebookIcon } from "../../Images/facebookIcon.svg";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import DashboardImage from "../../Images/carasoul.png";
import TransactionImage from "../../Images/carasoul.png";
import RecipientImage from "../../Images/carasoul.png";
import endlMainLogo from "../../Images/brandings/endlMainLogo.png";

const Login = ({
  handleInputChange,
  formData,
  formErrors,
  onSubmit,
  isLoading,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = useMemo(
    () => [DashboardImage, TransactionImage, RecipientImage],
    []
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2500);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <Box display="flex" width="100%" height="100vh" overflow="hidden">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex="1"
        padding={4}
        sx={{ minWidth: { xs: '100%', md: '50%' } }}
      >
        {/* Logo positioned at the top left corner */}
        <Box
          position="absolute"
          top={30}
          left={30}
          zIndex={1000} // Ensures the logo stays on top
        >
          <img src={endlMainLogo} alt="Logo" style={{ width: 100, height: 'auto' }} />
        </Box>

        <Box width={{xs: "90%",sm:"60%",md:"60%"}} display={"flex"} flexDirection={"column"} gap={4}>
          <Typography variant="h4">Login</Typography>
          <form
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
            }}
            onSubmit={onSubmit}
          >
            <Box gap={2} display={"flex"} flexDirection={"column"}>
              <Box>
                <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
                  Email Address
                </Typography>
                <TextField
                  error={!!formErrors.email}
                  variant="outlined"
                  placeholder="Enter your email"
                  name="email"
                  fullWidth
                  value={formData.email}
                  onChange={handleInputChange}
                  sx={{
                    "& .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "11.5px 14px",
                    },
                  }}
                />
                {formErrors.email && (
                  <Typography fontSize={13} color={"red"}>
                    {formErrors.email}
                  </Typography>
                )}
              </Box>
              <Box>
                <Typography fontSize={13} color={"rgba(0,0,0,0.8)"}>
                  Password
                </Typography>
                <OutlinedInput
                  error={!!formErrors.password}
                  variant="outlined"
                  name="password"
                  placeholder="Enter your password"
                  fullWidth
                  type={showPassword ? "text" : "password"}
                  value={formData.password}
                  onChange={handleInputChange}
                  sx={{
                    "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                      padding: "11.5px 14px",
                    },
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword((prev) => !prev)}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formErrors.password && (
                  <Typography fontSize={13} color={"red"}>
                    {formErrors.password}
                  </Typography>
                )}

                <Link
                  href="/forget-password"
                  color={"rgba(0,0,0)"}
                  fontWeight={600}
                  fontSize={13}
                  textAlign={"right"}
                  display={"block"}
                >
                  Forgot password
                </Link>
              </Box>
            </Box>

            <Box display={"flex"} flexDirection={"column"} gap="5px">
              <Button
                fullWidth
                sx={{
                  backgroundColor: "#462A9C",
                  color: "white",
                  textTransform: "none",
                  height: 40,
                  "&:hover": {
                    backgroundColor: "#462A9C",
                  },
                  "&:disabled": {
                    color: "white",
                  },
                }}
                type="submit"
                disabled={isLoading}
                onClick={onSubmit}
                endIcon={
                  isLoading && (
                    <CircularProgress
                      size={20}
                      sx={{
                        color: "white",
                      }}
                    />
                  )
                }
              >
                Login
              </Button>
              <Typography fontSize={14} style={{color : "#87878A"}}>
                Don't have an account?{" "}
                <Link href="/signup" color={"rgba(0,0,0)"} fontWeight={600}>
                  Sign up
                </Link>
              </Typography>
            </Box>
          </form>
          {/* <Divider>or</Divider>
          <Box display={"flex"} gap={1} flexDirection={"column"}>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                color: "rgba(0,0,0,0.8)",
                textTransform: "none",
                borderColor: "rgba(0,0,0,0.4)",
                height: 40,
              }}
              startIcon={<GoogleIcon height={22} width={22} />}
            >
              Continue with Google
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                color: "rgba(0,0,0,0.8)",
                textTransform: "none",
                borderColor: "rgba(0,0,0,0.4)",
                height: 40,
              }}
              startIcon={<FacebookIcon height={22} width={22} />}
            >
              Continue with Facebook
            </Button>
            <Button
              fullWidth
              variant="outlined"
              sx={{
                color: "rgba(0,0,0,0.8)",
                textTransform: "none",
                borderColor: "rgba(0,0,0,0.4)",
              }}
              startIcon={<LinkedInIcon height={22} width={22} />}
            >
              Continue with Linkedin
            </Button>
          </Box> */}
        </Box>
      </Box>
      <Box
        display={{xs:"none" , md:"flex"}}
        flexDirection={"column"}
        justifyContent={"space-between"}
        gap={2}
        width={"100%"}
        height={"100%"}
        sx={{
          backgroundColor: `#EDE8FC`,
          pl: 10,
          pt: 10,
          pb: 5,
          position: "relative",
        }}
      >
        <Stack gap={2}>
        <Typography variant="h5">
          Unified payments platform<br /> for fiat and virtual assets
        </Typography>
        <Typography variant="body1" fontSize={12}>
          endl enables seamless multicurrency transactions, <br /> and on-off ramps.
        </Typography>
        </Stack>

        <Box
          overflow={"hidden"}
          width={"80vw"}
          height={"60vh"}
          border={'1px solid #000000a3'}
          borderRadius={2}
          sx={{
            backgroundImage : `url('${images[currentImageIndex]}')`,
            backgroundSize:"100%"
          }}
        >
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
