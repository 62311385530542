import React, { useState, useCallback, useEffect } from "react";
import { Popover, Box, Grid, Button, FormControlLabel, Typography } from "@mui/material";
import { getCurrency } from "../../lib/service/DashboardApi/dashboardApi";
import "./FilterPayment.css";
import Checkbox from "../Common/Checkbox";

const importAll = (r) => {
  let images = {};
  r.keys().map((item, index) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};
const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const FilterPayment = ({ anchorEl, handleClick, handleApplyFilters, resetFilters }) => {
  const [sourceRegularCurrencies, setSourceRegularCurrencies] = useState([]);
  const [sourceCurrency, setSourceCurrency] = useState({});
  const [paymentType, setPaymentType] = useState({
    fiatToFiat: false,
    fiatToCrypto: false,
    cryptoToFiat: true,
  });
  const [paymentMethod, setPaymentMethod] = useState({
    connectToWallet: false,
    manualCryptopayment: false,
    creditDebitCard: false,
    aeonAccount: false,
  });
  const [recipientType, setRecipientType] = useState({
    individual: false,
    business: false,
  });

  const handlePaymentType = (event) => {
    setPaymentType({
      ...paymentType,
      [event.target.name]: event.target.checked,
    });
  };

  const handlePaymentMethodChange = (event) => {
    setPaymentMethod({
      ...paymentMethod,
      [event.target.name]: event.target.checked,
    });
  };

  const handleCurrencyChange = (event) => {
    const { name, checked } = event.target;
    setSourceCurrency((prevSourceCurrency) => ({
      ...prevSourceCurrency,
      [name]: checked,
    }));
  };

  const handleRecipientType = (event) => {
    setRecipientType({
      ...recipientType,
      [event.target.name]: event.target.checked,
    });
  };

  const handleClose = () => {
    setPaymentType({
      fiatToFiat: false,
      fiatToCrypto: false,
      cryptoToFiat: true,
    });
    setPaymentMethod({
      connectToWallet: false,
      manualCryptopayment: false,
      creditDebitCard: false,
      aeonAccount: false,
    });
    setRecipientType({
      individual: false,
      business: false,
    })
    setSourceCurrency({});
    handleClick(null);
  };

  const fetchCurrencyList = useCallback(async () => {
    try {
      const response = await getCurrency({});
      const { source } = response.data;
      setSourceRegularCurrencies(source.currency);
    } catch (error) {
      console.error("Error fetching currency list:", error);
    }
  }, []);

  useEffect(() => {
    fetchCurrencyList();
    if(resetFilters){
      setPaymentType({
        fiatToFiat: false,
        fiatToCrypto: false,
        cryptoToFiat: true,
      });
      setPaymentMethod({
        connectToWallet: false,
        manualCryptopayment: false,
        creditDebitCard: false,
        aeonAccount: false,
      });
      setRecipientType({
        individual: false,
        business: false,
      })
      setSourceCurrency({});
    }
  }, [resetFilters]);

  const handleFilters = () => {
    handleApplyFilters(paymentType, paymentMethod, recipientType, sourceCurrency);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={() => handleClose(null)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      style={{
        top: "48px",
      }}
    >
      <Box sx={{ p: "15px 15px 40px" }}>
        <Button
          variant="contained"
          onClick={handleFilters}
          style={{
            fontFamily: "PPMori-Regular",
            backgroundColor: "#462A9C",
            marginBottom: "10px",
            fontSize: "10px",
            width: "55%",
            marginRight: "8px",
          }}
        >
          Apply Filters
        </Button>
        <Button
          onClick={handleClose}
          variant="contained"
          style={{
            fontFamily: "PPMori-Regular",
            backgroundColor: "#FFFFFF",
            color: "#462A9C",
            border: "2px solid #462A9C",
            marginBottom: "10px",
            fontSize: "10px",
            width: "40%",
          }}
        >
          Reset
        </Button>
        <Box sx={{ borderBottom: "1px solid #ccc", marginBottom: "10px" }} />
        <Typography variant="h6" sx={{ fontSize: "14px", fontFamily: "PPMori-Regular", fontWeight: "600" }}>
          Payment Type
        </Typography>
        <Grid>
          <Checkbox disabled checked={paymentType.fiatToFiat} onChange={handlePaymentType} name="fiatToFiat" label="Fiat to Fiat " />
        </Grid>
        <Grid>
          <Checkbox disabled checked={paymentType.fiatToCrypto} onChange={handlePaymentType} name="fiatToCrypto" label="Fiat to Crypto"/>
        </Grid>
        <Grid>
          <Checkbox checked={paymentType.cryptoToFiat} onChange={handlePaymentType} name="cryptoToFiat" label="Crypto to Fiat"/>
        </Grid>
        <Box sx={{ borderBottom: "1px solid #ccc", marginBottom: "10px" }} />
        <Typography variant="h6" sx={{ fontSize: "14px", fontFamily: "PPMori-Regular", fontWeight: "600" }}>
          Deposit Method
        </Typography>
        <Grid>
          <Checkbox checked={paymentMethod.connectToWallet} onChange={handlePaymentMethodChange} name="connectToWallet" label="Connected Wallet"/>
        </Grid>
        <Grid>
          <Checkbox checked={paymentMethod.manualCryptopayment} onChange={handlePaymentMethodChange} name="manualCryptopayment" label="Manual Wallet Payment"/>
        </Grid>
        {/* <Grid>
          <FormControlLabel
            control={<Checkbox style={{ color: "#DDDAD7" }} checked={paymentMethod.creditDebitCard} onChange={handlePaymentMethodChange} name="creditDebitCard" />}
            label="Credit/Debit Card"
          />
        </Grid> */}
        {/* <Grid>
          <FormControlLabel
            control={<Checkbox style={{ color: "#DDDAD7" }} checked={paymentMethod.aeonAccount} onChange={handlePaymentMethodChange} name="aeonAccount" />}
            label="Endl Account"
          />
        </Grid> */}
        <Box sx={{ borderBottom: "1px solid #ccc", marginBottom: "10px" }} />
        <Typography variant="h6" sx={{ fontSize: "14px", fontFamily: "PPMori-Regular", fontWeight: "600" }}>
          Recipient Type
        </Typography>
        <Grid>
          <Checkbox checked={recipientType.individual} onChange={handleRecipientType} name="individual" label="Individual payment"/>
        </Grid>
        <Grid>
          <Checkbox style={{ color: "#000" }} checked={recipientType.business} onChange={handleRecipientType} name="business" label="Business payment"/>
        </Grid>
        <Box sx={{ borderBottom: "1px solid #ccc", marginBottom: "10px" }} />
        <Typography variant="h6" sx={{ fontSize: "0.875rem", fontFamily: "PPMori-Regular", fontWeight: "bold" }}>
          Currency
        </Typography>
        {sourceRegularCurrencies.map((currency) => {
          const countryCode = currency.isCrypto ? currency.code.toLowerCase() : currency.country.toLowerCase();
          const imagePath = images[`${countryCode}.png`];
          return(
          <Grid key={currency.id} container alignItems="center">
            <Grid item xs={6}>
              <Checkbox checked={sourceCurrency[currency.name]} onChange={handleCurrencyChange} name={currency.code} label={currency.name}/>
            </Grid>
            <Grid item xs={6} container justifyContent="flex-end">
              <img src={imagePath} alt={currency.code} style={{ width: "20px", marginLeft: "10px" }} />
            </Grid>
          </Grid>
        )})}
      </Box>
    </Popover>
  );
};

export default FilterPayment;
