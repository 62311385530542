import React from "react";
import {
  Box,
  Divider,
  InputAdornment,
  OutlinedInput,
  Button,
  Typography,
} from "@mui/material";
const importAll = (r) => {
  let images = {};
  r.keys().forEach((item) => {
    images[item.replace("./", "")] = r(item);
  });
  return images;
};

const images = importAll(require.context("../../Images/flags", false, /\.(png)$/));

const TransferAmountForm = ({
  transferFrom,
  transferTo,
  transferAmount,
  handleAmountChange,
  handleGoBack,
  handleNext,
}) => {
  return (
    <Box display={"flex"} gap={5} flexDirection={"column"}>
      <Box
        sx={{
          border: "1px solid rgba(0,0,0,0.40)",
          borderRadius: 4,
          minWidth: 500,
          overflow: "hidden",
        }}
      >
        <Box
          bgcolor={"rgba(0,0,0,0.05)"}
          display={"flex"}
          gap={1}
          sx={{ padding: 2 }}
          borderBottom={1}
          borderColor={"1px solid rgba(0,0,0,0.20)"}
        >
          <Box width={"100%"}>
            <Typography fontWeight={600} fontSize={14}>
              You send
            </Typography>
          </Box>
          <Box width={"100%"}>
            <Typography fontWeight={600} fontSize={14}>
              Adds to account
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{ padding: 3 }}
          display={"flex"}
          flexDirection={"column"}
          gap={4}
        >
          <Box display={"flex"} gap={1}>
            <Box width={"100%"}>
              <OutlinedInput
                type="number"
                sx={{
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "12px  14px",
                    borderRight: "1px solid rgba(0,0,0,0.20)",
                  },
                }}
                placeholder="0"
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Typography fontSize={14}>
                      {transferFrom?.currency}
                    </Typography>
                  </InputAdornment>
                }
                onChange={handleAmountChange}
              />
            </Box>
            <Box width={"100%"}>
              <OutlinedInput
                sx={{
                  "& .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
                    padding: "12px  14px",
                    borderRight: "1px solid rgba(0,0,0,0.20)",
                  },
                }}
                inputProps={{
                  style: {
                    py: 1,
                  },
                }}
                endAdornment={
                  <InputAdornment
                    position="end"
                    sx={{
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <Typography fontSize={14}>{transferTo?.currency}</Typography>
                  </InputAdornment>
                }
              />
            </Box>
          </Box>
          <Divider />
          <Box display={"flex"} flexDirection={"column"} gap={1}>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
                Exchange Rate
              </Typography>
              <Typography
                color={"rgba(0,0,0,0.70)"}
                textAlign={"right"}
                fontSize={14}
              >
                1 SGD = 22.83 TRY
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
                Send time
              </Typography>
              <Typography
                color={"rgba(0,0,0,0.70)"}
                textAlign={"right"}
                fontSize={14}
              >
                Instant
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
                Fixed fee
              </Typography>
              <Typography textAlign={"right"} fontWeight={600} fontSize={14}>
                0 SGD
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
                Variable fee
              </Typography>
              <Typography textAlign={"right"} fontWeight={600}>
                0 SGD
              </Typography>
            </Box>
            <Box display={"flex"} justifyContent={"space-between"}>
              <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
                Total fees
              </Typography>
              <Typography textAlign={"right"} fontWeight={600} fontSize={14}>
                0 SGD
              </Typography>
            </Box>
          </Box>
          <Divider />
          <Box display={"flex"} justifyContent={"space-between"}>
            <Typography color={"rgba(0,0,0,0.50)"} fontSize={14}>
              Total amount to send
            </Typography>
            <Typography
              textAlign={"right"}
              fontWeight={600}
              variant="h6"
              fontSize={14}
            >
              0.00 SGD
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box wrap="noWrap" display={"flex"} gap={1} width={"100%"}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            textTransform: "none",
            borderColor: "#462A9C",
            color: "#462A9C",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          fullWidth
          onClick={handleGoBack}
        >
          Go back
        </Button>

        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            backgroundColor: "#462A9C",
            color: "#ffffff",
            fontSize: "12px",
            fontFamily: "PPMori-Regular",
            width: "100%",
            py: 1,
          }}
          onClick={handleNext}
          // disabled={!transferAmount || transferAmount === 0}
        >
          {" "}
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default TransferAmountForm;
