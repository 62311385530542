import React from "react";
import { Box, Typography, Tooltip, Modal, IconButton, Divider } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'; // Icon for currency
import TrendingUpIcon from '@mui/icons-material/TrendingUp'; // Icon for exchange rate

const ChargesModal = ({ chargesFee, chargeTotal, showCharges, onClose, sendAmount, sendCurrency, receiveAmount, receiveCurrency }) => {
  return (
    <div>
      {/* Modal */}
      <Modal open={showCharges} onClose={onClose}>
        <Box
          display="flex"
          flexDirection="column"
          p={4}
          bgcolor="background.paper"
          borderRadius={3}
          boxShadow={5}
          maxWidth={450}
          mx="auto"
          my="15vh"
          outline="none"
          sx={{
            "&:focus": {
              outline: "none"
            }
          }}
        >
          <Typography mb={2} style={{ fontWeight: 'bold', color: '#000' }}>
            Currency Calculator Details
          </Typography>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="body1" style={{ fontSize: "16px " }}>
              You send
            </Typography>
            <Typography variant="body1" style={{ fontSize: "16px", color: '#000', fontWeight: 'bold' }}>
              {sendAmount} {sendCurrency}
            </Typography>
          </Box>
          <Box display="flex" mt={1} mb={1} justifyContent="space-between" alignItems="center">
            <Typography variant="body1" style={{ fontSize: "16px" }}>
              Recipient gets
            </Typography>
            <Typography variant="body1" style={{ fontSize: "16px", color: '#000', fontWeight: 'bold' }}>
              {receiveAmount} {receiveCurrency}
            </Typography>
          </Box>
          <Divider />
          <Typography variant="h5" mt={1} mb={1} style={{ fontWeight: 'bold', color: '#000', fontSize: "16px" }}>
            Fee Breakdown
          </Typography>
          {chargesFee?.map((fee, index) => (
            <React.Fragment key={index}>
              <Box display="flex" justifyContent="space-between" gap={2} mb={1}>
                <Typography fontSize={14}>
                  {fee.chargeName}
                </Typography>
                <Typography fontSize={14} style={{ fontWeight: 'bold', color: '#000' }}>
                  {fee.chargeAmount} {fee.chargeCurrency}
                </Typography>
              </Box>
              {index === chargesFee.length - 1 && (
                <>
                  <Divider />
                  <Box mt={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="body1" style={{ fontSize: "16px", fontWeight: 'bold' }}>
                      Total Fee
                    </Typography>
                    <Typography variant="body1" style={{ fontSize: "16px", color: '#000', fontWeight: 'bold' }}>
                      {chargeTotal} {fee.chargeCurrency}
                    </Typography>
                  </Box>
                </>
              )}
            </React.Fragment>
          ))}

          <Divider sx={{ my: 2 }} />

          {/* How amount is calculated */}
          <Box>
            <Typography variant="body1" style={{ fontWeight: 'bold', color: '#000' }}>
              How Your Amount is Calculated:
            </Typography>
            <Box display="flex" alignItems="center" gap={1} mt={1}>
              <TrendingUpIcon fontSize="small" color="action" />
              <Typography style={{ fontSize: "14px", fontFamily: "PPMori-Regular" }}>
                <span style={{ fontWeight: 'bold' }}>Recipient Gets =</span> (You Send - Total Fees) * Exchange Rate
              </Typography>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ChargesModal;
