import React, { useEffect, useState } from "react";
import { Box, Grid, Typography, Button, Divider, Paper } from "@mui/material";
import AllAccountsTable from "../../components/TableComponent/AllAccountsTable";
import OpenAccountModal from "../../components/ModalComponent/OpenAccountModal";
import InternalTransferModal from "../../components/ModalComponent/InternalTransfarModal/InternalTransferModal";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
const Accounts = ({ accountsDetails, isLoading, onAccountOpen }) => {
  const [openAccountModal, setOpenAccountModal] = useState(false);
  const [internalTransferModalOpen, setInternalTransferModalOpen] =
    useState(false);

  console.log(accountsDetails);
  return (
    <>
      {openAccountModal && (
        <OpenAccountModal
          open={openAccountModal}
          onClose={() => setOpenAccountModal(false)}
          onAccountOpen={onAccountOpen}
        />
      )}
      {internalTransferModalOpen && (
        <InternalTransferModal
          open={internalTransferModalOpen}
          onClose={() => setInternalTransferModalOpen(false)}
        />
      )}

      <Box display={"flex"} flexDirection={"column"} height={"100svh"}>
        <Box
          container
          display={"flex"}
          flexDirection={"column"}
          padding={3}
          gap={3}
          style={{
            backgroundColor: "#F6F5F9",
            width: "100%",
            margin: 0,
          }}
          pb={3}
        >
          <Grid item>
            <Typography
              variant="h3"
              fontSize="26px"
              fontFamily="PPMori-Regular"
            >
              Accounts
            </Typography>
          </Grid>
          <Grid item>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Box display={"flex"} gap={2}>
                <Box display={"flex"} flexDirection={"column"} gap={1}>
                  <Typography variant="p" fontFamily="PPMori-Regular">
                    Total accounts balance
                  </Typography>
                  <Typography
                    variant="h3"
                    fontSize="26px"
                    fontFamily="PPMori-Regular"
                  >
                    {accountsDetails
                      ? ` ${accountsDetails.total_accounts_balance} ${accountsDetails.currency ?? "USD"}`
                      : "0.00 USD"}
                  </Typography>
                  {accountsDetails && accountsDetails.accounts.length > 1 && (
                    <Typography
                      component={"small"}
                      fontSize={12}
                      color={"rgba(0,0,0,0.70)"}
                    >
                      among {accountsDetails.accounts.length} accounts
                    </Typography>
                  )}
                </Box>
                  <hr />
                  <Box display={"flex"} flexDirection={"column"} gap={1}>
                    <Typography variant="p" fontFamily="PPMori-Regular">
                    Pending internal transfers
                    </Typography>
                    <Typography
                      variant="h3"
                      fontSize="26px"
                      fontFamily="PPMori-Regular"
                    >
                      {accountsDetails ? `${accountsDetails.pending_internal_transactions} ${accountsDetails.currency ?? "USD"}` : "0.00 USD"}
                    </Typography>
                    {accountsDetails && (
                      <Typography
                        component={"small"}
                        fontSize={12}
                        color={"rgba(0,0,0,0.70)"}
                      >
                        Live update
                      </Typography>
                    )}
                  </Box>
              </Box>
              {!isLoading && 
                <>
                 {( !accountsDetails || accountsDetails.accounts.length === 0) ? (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setOpenAccountModal(true)}
                      style={{
                        backgroundColor: "#462A9C",
                        color: "#FFFFF",
                        textTransform: "none",
                        fontFamily: "PPMori-Regular",
                      }}
                    >
                      Open account
                    </Button>
                  ) : (
                    accountsDetails.accounts.length > 2 && (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#462A9C",
                          color: "#FFFF",
                          textTransform: "none",
                          fontFamily: "PPMori-Regular",
                        }}
                        onClick={() => setInternalTransferModalOpen(true)}
                        startIcon={<SyncAltIcon />}
                      >
                        Transfer between accounts
                      </Button>
                    )
                  )}
                </>
                }   
            </Box>
          </Grid>
        </Box>

        <Box padding={3} flexGrow={1} overflow={"hidden"}>
          <Paper variant="outlined">
            <AllAccountsTable
              rows={accountsDetails?.accounts}
              loading={isLoading}
            />
            {accountsDetails?.accounts && (
              <Button
                variant="text"
                sx={{
                  color: "rgba(0,0,0)",
                  ml: 1.7,
                  my: 1,
                  textTransform: "none",
                  fontWeight: 600,
                  "&:hover": "none",

                }}
                onClick={() => setOpenAccountModal(true)}
                startIcon={
                  <Box
                    border={1}
                    borderColor={"#462A9C"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    borderRadius={1}
                    padding={0.5}
                  >
                    <AddOutlinedIcon style={{ color: "#462A9C" }} />
                  </Box>
                }
              >
                Open new account
              </Button>
            )}
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default Accounts;
