import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute, PublicRoute } from "../utils/Routes.helpers";
import Login from "../container/LoginAndSignupContainer/LoginContainer";
import SignUp from "../container/LoginAndSignupContainer/SignUpContainer";
import ForgetPassword from "../container/LoginAndSignupContainer/ForgetPasswordContainer";
import TwoFALogin from "../container/LoginAndSignupContainer/TwoFALoginContainer";
import BaseLayout from "../layouts/BaseLayout/BaseLayout";
import PrivacyModal from "../container/PrivacyComponent/PrivacyComponent";
import TermsOfUseModal from "../container/TermsOfUse/TermsAndCondition";
import NotFoundPage from "../pages/Notfound/NotFoundPage";
import RoutesConfig from "./Routes";
import { useSelector } from "react-redux";

const AppNavigator = () => {
  const isLoggedIn = useSelector(state => state.user.isAuthenticated);
  const [isTermsAndConditionModalVisible, setIsTermsAndConditionModalVisible] = React.useState(false);
  const [isPrivacyPolicyModalVisible, setIsPrivacyPolicyModalVisible] = React.useState(false);

  const toggleTermsAndConditionModal = () => {
    setIsTermsAndConditionModalVisible(!isTermsAndConditionModalVisible);
  }

  const togglePrivacyPolicyModalVisible = () => {
    setIsPrivacyPolicyModalVisible(!isPrivacyPolicyModalVisible);
  }

  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={<PublicRoute authToken={isLoggedIn} element={Login} />}
        />
        <Route
          path="/signup"
          element={<PublicRoute authToken={isLoggedIn} element={SignUp} />}
        />
        <Route
          path="/2fa-login"
          element={<PublicRoute authToken={isLoggedIn} element={TwoFALogin} />}
        />
        <Route
          path="/forget-password"
          element={
            <PublicRoute authToken={isLoggedIn} element={ForgetPassword} />
          }
        />
        <Route element={<BaseLayout />}>
          {RoutesConfig.map((route, index) => {
            return (
              <Route
                key={`${route.path}_${index}`}
                path={route.path}
                element={
                  <ProtectedRoute
                    authToken={isLoggedIn}
                    element={route.component}
                  />
                }
              />
            );
          })}
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
      <PrivacyModal
        open={isPrivacyPolicyModalVisible}
        handleClose={togglePrivacyPolicyModalVisible}
      />
      <TermsOfUseModal
        open={isTermsAndConditionModalVisible}
        handleClose={toggleTermsAndConditionModal}
      />
    </>
  );
};

export default AppNavigator;
